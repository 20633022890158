<template>
  <DialogVue
    as="div"
    class="relative z-10"
    @close="showCurrentRequest = false"
    :open="showCurrentRequest"
  >
    <div class="fixed inset-0" />

    <div class="fixed inset-0">
      <div class="absolute inset-0">
        <div
          class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
        >
          <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
            <div
              class="flex h-full flex-col overflow-y-auto bg-slate-600 py-6 shadow-xl"
            >
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <DialogTitle class="text-slate-300 text-lg"
                    >Request Information</DialogTitle
                  >
                  <div class="ml-3 flex h-7 items-center">
                    <button
                      type="button"
                      class="rounded-md text-slate-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      @click="showCurrentRequest = false"
                    >
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative mt-6 flex-1 px-4 sm:px-6 font-mono">
                <span class="text-slate-400">Request:</span>
                <pre v-highlightjs>
                  <code class="json rounded">{{ JSON.stringify(currentRequest.request, null, 2) }}</code></pre>
              </div>
              <div
                class="relative mt-6 flex-1 px-4 sm:px-6"
                v-if="currentRequest.response"
              >
                <span class="text-slate-400">Response:</span>
                <pre v-highlightjs>
                  <code class="json">{{ JSON.stringify(currentRequest.response, null, 2) }}</code></pre>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </div>
  </DialogVue>

  <div class="flex flex-col flex-1 min-h-0">
    <div
      class="flex border-b border-slate-800 items-center justify-between p-2 pt-3"
      v-if="!isEmbedded"
    >
      <div class="relative">
        <label
          for="name"
          class="absolute -top-2 left-2 inline-block px-1 text-white"
          >Filter</label
        >
        <input
          type="text"
          name="name"
          v-model="filterText"
          id="name"
          class="block w-full cursor-pointer bg-slate-800 border-slate-900 text-slate-400 hover:bg-slate-700 items-center rounded-r-sm px-4 py-2 text-sm font-medium"
          placeholder=""
        />
      </div>

      <div class="space-x-3 xl:block hidden mx-2 font-mono">
        <a
          v-for="type of requestTypes"
          :key="type"
          @click.prevent="selectType(type)"
          :class="[
            filterType === type
              ? 'border-b text-blue-500 font-bold border-blue-500'
              : '',
            'text-md cursor-pointer select-none text-slate-500',
          ]"
          >{{ type }}</a
        >
      </div>
    </div>

    <div
      class="flex grow border-blue-500 overflow-y-auto p-2"
      style="background-color: #010917"
    >
      <table class="min-w-full divide-y divide-slate-800 font-mono">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-0"
            >
              Time
            </th>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-300 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-slate-300"
            >
              Status
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-slate-300"
            >
              Type
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-slate-300"
            >
              Initiator
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-slate-300"
            >
              MS
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-slate-800">
          <tr
            v-for="event of filteredEvents"
            :key="event.requestId"
            @click="focusCurrentRequest(event)"
            :title="event.url"
            class="hover:underline cursor-pointer"
          >
            <td class="whitespace-nowrap px-1 py-4 text-sm text-gray-500">
              <span
                class="select-none text-sm font-medium text-blue-500 whitespace-nowrap rounded-md cursor-pointer font-mono hover:underline"
                @click="setVideoTime(event.from)"
              >
                {{ timeDisplay(event.from) }}
              </span>
            </td>
            <td
              class="hover:underline whitespace-nowrap py-4 pl-4 pr-3 text-sm text-slate-300 sm:pl-0 overflow-hidden text-ellipsis cursor-pointer"
              style="max-width: 200px"
              @click="focusCurrentRequest(event)"
              :class="{ 'opacity-50': event.from > videoTime }"
            >
              {{ event.name }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{
                !isPlaying
                  ? event.response?.statusCode
                  : event.to <= videoTime && event.response?.statusCode
                  ? event.response?.statusCode
                  : ""
              }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ event.type }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ event.initiator }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-end"
            >
              {{
                isPlaying
                  ? event.from > videoTime
                    ? ""
                    : event.to <= videoTime && event.duration !== null
                    ? Math.round(event.duration)
                    : "Pending"
                  : event.duration !== null
                  ? Math.round(event.duration)
                  : "Pending"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  Dialog as DialogVue,
  DialogPanel,
  DialogTitle,
  // TransitionChild,
  // TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import moment from "moment";

const typeMap = {
  main_frame: "doc",
  sub_frame: "doc",
  stylesheet: "css",
  script: "js",
  image: "img",
  font: "font",
  object: "",
  xmlhttprequest: "xhr",
  ping: "xhr",
  csp_report: "",
  media: "media",
  websocket: "ws",
  webbundle: "",
  other: "other",
};
const omittedProps = [
  "documentId",
  "documentLifecycle",
  "frameId",
  "frameType",
  "parentFrameId",
  "requestId",
  "tabId",
  "time",
  "requestHeaders",
  "responseHeaders",
  // "type",
  // "timeStamp",
];
export default {
  components: {
    DialogVue,
    DialogPanel,
    DialogTitle,
    // TransitionChild,
    // TransitionRoot,
    XMarkIcon,
  },
  props: ["networkEvents", "videoTime", "isPlaying"],
  emits: ["setVideoTime", "manualScroll"],
  data() {
    return {
      requestTypes: [
        "all",
        "xhr",
        "js",
        "css",
        "img",
        "media",
        "font",
        "doc",
        "ws",
        "other",
      ],
      filterText: "",
      filterType: "all",
      currentRequest: false,
      showCurrentRequest: false,
    };
  },
  computed: {
    isEmbedded() {
      return this.$route.query.embed === "true";
    },
    searchTerms() {
      return this.filterText
        .split(" ")
        .map((s) => s.trim().toLowerCase())
        .filter((s) => s.length);
    },
    consolidatedEvents() {
      const groupedEvents = this.networkEvents.reduce((result, event) => {
        let payload;
        switch (event.type) {
          case "NETWORK_BEFORE_REQUEST":
            // eslint-disable-next-line no-case-declarations
            payload = _.omit(event.payload, omittedProps);
            // eslint-disable-next-line no-param-reassign
            result[event.payload.requestId] = {
              request: {
                ...payload,
                time: event.time,
                headers: event.payload.requestHeaders ?? [],
              },
            };
            break;
          case "NETWORK_ERROR_REQUEST":
          case "NETWORK_COMPLETED_REQUEST":
            if (!result[event.payload.requestId]) break;
            // eslint-disable-next-line no-case-declarations
            payload = _.omit(event.payload, omittedProps);
            // eslint-disable-next-line no-param-reassign
            result[event.payload.requestId].response = {
              ...payload,
              time: event.time,
              headers: event.payload.responseHeaders ?? [],
            };
            break;
          case "NETWORK_RESPONSE_BODY":
            if (!result[event.payload.requestId]) break;
            // eslint-disable-next-line no-param-reassign
            result[event.payload.requestId].response.body =
              event.payload.responseBody;
            break;
          default:
            console.error(
              `event of type ${event.type} is not handled by the NetworkTab.vue component`
            );
            break;
        }
        return result;
      }, {});

      return Object.values(groupedEvents)
        .map((event) => {
          const url = event.response?.url ?? event.request.url;
          let name = new URL(url).host;
          const pathParts = new URL(url).pathname
            .split("/")
            .map((p) => p.trim())
            .filter((p) => p.length);
          if (pathParts.length) name = _.last(pathParts);
          // This is to cover if the request wasn't done while we stopped recording
          const endTime = event.response?.timeStamp || null;
          const type = event.response?.type ?? event.request.type;
          return {
            ...event,
            url,
            name,
            from: event.request.time, // This is video time
            to: event.response?.time ?? null, // This is video time
            initiator: event.request.initiator,
            requestId: event.request.requestId,
            type: type === "main_frame" ? "document" : type,
            // This is an accurate duration using browser timestamps
            duration: endTime ? endTime - event.request.timeStamp : null,
          };
        })
        .sort((e1, e2) => e1.from - e2.from);
    },
    filteredEvents() {
      return this.consolidatedEvents.filter(
        (event) =>
          this.searchTerms.every((term) =>
            event.url.toLowerCase().includes(term)
          ) &&
          (this.filterType === "all" ||
            typeMap[event.type] === this.filterType) &&
          (!this.isPlaying || event.from <= this.videoTime)
      );
    },
  },
  methods: {
    selectType(type) {
      this.filterType = type;
    },
    setVideoTime(time) {
      this.$emit("setVideoTime", time);
    },
    focusCurrentRequest(request) {
      this.currentRequest = request;
      this.showCurrentRequest = true;
    },
    timeDisplay(duration) {
      let d = duration;
      if (d < 0) {
        d = 0;
      }
      const minutes = moment(d).minutes().toString().padStart(2, 0);
      const seconds = moment(d).seconds().toString().padStart(2, 0);
      return `${minutes}:${seconds}`;
    },
  },
};
</script>

<template>
  <div ref="scrollerParent" class="flex-1 m-2"></div>
</template>

<script>
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { SearchAddon } from "xterm-addon-search";

import "xterm/css/xterm.css";

// 1000 lines is the default scrollback limit (the max lines in the terminal)
const SCROLLBACK_LIMIT = 1000;
const terminalOptions = {
  scrollback: SCROLLBACK_LIMIT,
  cursorBlink: false,
  fontFamily:
    'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  theme: {
    background: "#060c18",
  },
  allowProposedApi: true,
};

export default {
  props: ["items", "time", "filterText"],
  emits: ["updated-results"],
  data() {
    return {
      currentItem: 0,
      resizeObserver: null,
      terminal: null,
      fitAddon: null,
      searchAddon: null,
    };
  },
  watch: {
    items(items, oldItems) {
      if (items === oldItems) return;
      this.terminal?.reset();
      this.currentItem = 0;
      this.render(this.time);
    },
    time(time) {
      this.render(time);
    },
    filterText() {
      this.findNext();
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.renderScrollingLogsSize);
    this.resizeObserver.observe(this.$refs.scrollerParent);

    this.terminal = new Terminal(terminalOptions);
    this.fitAddon = new FitAddon();
    this.searchAddon = new SearchAddon();
    this.searchAddon.onDidChangeResults(this.updatedResults.bind(this));
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(this.searchAddon);
    this.terminal.open(this.$refs.scrollerParent);
  },
  beforeUnmount() {
    this.resizeObserver?.disconnect();
    try {
      this.terminal.dispose();
    } catch (err) {
      console.error("When disposing of terminal, encountered error", err);
    }
  },
  methods: {
    findNext() {
      this.searchAddon?.findNext(this.filterText, {
        decorations: {
          // matchBackground: "#000000",
          activeMatchBackground: "#C0C0C0",
        },
      });
    },
    findPrevious() {
      this.searchAddon?.findPrevious(this.filterText, {
        decorations: {
          // matchBackground: "#000000",
          activeMatchBackground: "#C0C0C0",
        },
      });
    },
    render(time) {
      if (!this.terminal) return;
      const numberOfItemsToDisplay =
        time === 0
          ? this.items.length
          : this.items.findLastIndex((item) => item.time <= time) + 1;

      let reset = false;
      if (this.currentItem > numberOfItemsToDisplay) {
        reset = true;
        this.terminal.reset();
        this.currentItem = 0;
      }
      if (this.currentItem < numberOfItemsToDisplay) {
        const start = Math.max(
          this.currentItem,
          numberOfItemsToDisplay - SCROLLBACK_LIMIT * 2
        );

        for (let i = start; i < numberOfItemsToDisplay; i += 1) {
          this.terminal.writeln(this.items[i].line);
        }
      }
      this.currentItem = numberOfItemsToDisplay;
      if (reset) {
        this.findNext();
      }
    },
    renderScrollingLogsSize() {
      this.fitAddon?.fit();
    },
    updatedResults({ resultIndex: index, resultCount: count }) {
      this.$emit("updated-results", { index, count });
    },
  },
};
</script>

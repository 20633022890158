<template>
  <div class="flex flex-1 flex-col min-h-0" @wheel="manualScroll">
    <div class="w-full mt-32" v-if="isLoadingLogs">
      <Spinner />
    </div>
    <div v-else class="flex flex-col min-h-0">
      <div v-if="!tabs.length" class="p-20">
        <div class="text-center">
          <font-awesome-icon
            icon="fa-brands fa-chrome"
            class="-ml-0.5 mr-1.5 mx-auto h-12 w-12 text-slate-400"
            aria-hidden="true"
          />

          <h3 class="mt-2 text-sm font-semibold text-slate-400">
            Attach console logs and network requests here.
          </h3>
          <p class="mt-1 text-sm text-slate-500">
            Install the Dashcam Chrome Extension and configure a
            <strong>web</strong> pattern.
          </p>
          <div class="mt-6">
            <a
              href="https://docs.dashcam.io/replayable/integrations/installation"
              class="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Learn How
            </a>
          </div>
        </div>
      </div>
      <div v-else class="pb-0 min-h-0 flex flex-col">
        <LogSelectorTabs
          v-if="tabs.length"
          class="cursor-pointer"
          :tabs="tabs"
          :video-time="videoTime"
          :selected-tab="selectedTab"
          :current-tab="currentTab"
          @tab-selected="setActiveTab"
        />

        <div class="border-slate-700 border-b">
          <div class="cursor-pointer select-none">
            <nav class="" aria-label="Tabs">
              <span
                @click="displayCategory = 'console'"
                :class="[
                  displayCategory == 'console'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-slate-500  hover:text-slate-700',
                  'group inline-flex items-center border-transparent text-slate-500 hover:text-slate-400 hover:border-b-slate-400 group inline-flex items-center border-b-2 xl:py-4 xl:px-4 h-12 px-4 text-sm font-medium cursor-pointer  border-r-slate-800 border-r',
                ]"
              >
                <font-awesome-icon
                  icon="fa-solid fa-terminal"
                  :class="[
                    displayCategory == 'console'
                      ? 'text-blue-500'
                      : 'text-slate-400 group-hover:text-slate-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  ]"
                  aria-hidden="true"
                />
                <span>Console</span>
              </span>
              <span
                @click="displayCategory = 'network'"
                :class="[
                  displayCategory == 'network'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-slate-500  hover:text-slate-700',
                  'group inline-flex items-center border-transparent text-slate-500 hover:text-slate-400 hover:border-b-slate-400 group inline-flex items-center border-b-2 xl:py-4 xl:px-4 h-12 px-4 text-sm font-medium cursor-pointer  border-r-slate-800 border-r',
                ]"
              >
                <font-awesome-icon
                  icon="fa-solid fa-network-wired"
                  :class="[
                    displayCategory == 'network'
                      ? 'text-blue-500'
                      : 'text-slate-400 group-hover:text-slate-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  ]"
                  aria-hidden="true"
                />
                <span>Network</span>
              </span>
            </nav>
          </div>
        </div>

        <div class="flex-1 min-h-0 flex flex-col">
          <ConsoleTab
            v-if="displayCategory === 'console'"
            :console-events="consoleEvents"
            :video-time="videoTime"
            :is-playing="isPlaying"
            @set-video-time="setVideoTime"
          />
          <NetworkTab
            v-if="displayCategory === 'network'"
            :network-events="networkEvents"
            :video-time="videoTime"
            :is-playing="isPlaying"
            @set-video-time="setVideoTime"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { datadogLogs as log } from "@datadog/browser-logs";
import { process } from "../../helpers/webLogs";
import Spinner from "./Spinner.vue";
import LogSelectorTabs from "./LogSelectorTabs.vue";
import ConsoleTab from "./ConsoleTab.vue";
import NetworkTab from "./NetworkTab.vue";

export default {
  components: { Spinner, LogSelectorTabs, ConsoleTab, NetworkTab },
  props: ["webLogs", "videoTime", "isLoadingLogs", "isPlaying"],
  emits: ["setVideoTime", "manualScroll"],
  data() {
    return {
      displayCategory: "console", // 'console' | 'network'
      selectedTab: null,
    };
  },
  computed: {
    processedLogs() {
      if (!this.webLogs) return null;
      return process(this.webLogs.lines);
    },
    tabs() {
      if (!this.processedLogs) return [];
      return Object.entries(this.processedLogs.tabs)
        .map(([tabId, sessions]) => ({
          id: parseInt(tabId, 10),
          sessions,
        }))
        .sort(
          (tab1, tab2) =>
            // TODO: Related to desktop trimming and webLogs.js TODO comment
            (this.processedLogs.activeTabs.find(
              (active) => active.tabId === tab1.id
            )?.from ?? 0) -
            (this.processedLogs.activeTabs.find(
              (active) => active.tabId === tab2.id
            )?.from ?? 0)
        );
    },
    currentTab() {
      const currentTab = this.processedLogs?.activeTabs?.find(
        (tab) =>
          (tab.from ?? -Infinity) <= this.videoTime &&
          (tab.to ?? Infinity) >= this.videoTime
      );
      return currentTab?.tabId ?? null;
    },
    consoleEvents() {
      if (!this.webLogs?.lines) return [];
      return this.webLogs?.lines?.filter(
        (event) =>
          [
            "LOG_EVENT",
            "LOG_ERROR",
            "NAVIGATION_STARTED",
            "SPA_NAVIGATION",
          ].includes(event.type) && event.payload.tabId === this.selectedTab
      );
    },
    networkEvents() {
      if (!this.webLogs?.lines) return [];
      return this.webLogs?.lines?.filter(
        (event) =>
          event.type.startsWith("NETWORK_") &&
          event.payload.tabId === this.selectedTab
      );
    },
  },
  watch: {
    tabs(tabs) {
      if (!tabs.find((tab) => tab.id === this.selectedTab))
        this.selectedTab = tabs[0]?.id ?? null;
    },
  },
  mounted() {
    if (this.tabs?.length) this.selectedTab = this.tabs[0]?.id ?? null;
  },
  methods: {
    manualScroll() {
      this.$emit("manualScroll");
    },
    setVideoTime(time) {
      this.$emit("setVideoTime", time);
    },
    setActiveTab(tab) {
      this.selectedTab = tab.id;
    },
  },
};
</script>

<template>
  <div class="flex items-center">
    <input
      class="rounded flex-1 h-10 outline-none px-5 bg-slate-900 shadow shadow-[inset_0_1px_2px_rgba(0,0,0,0.3)] border-slate-900 text-slate-400 rounded-r-none"
      v-model="comment"
      placeholder="Enter a comment"
      @keyup.enter="createComment"
      @keydown.stop=""
    />
    <button
      @click="createComment()"
      type="button"
      class="inline-flex items-center px-4 py-2 border border-transparent shadow text-sm font-medium font-mono rounded text-slate-400 bg-slate-700 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-l-none"
    >
      Comment at {{ formatTimestamp(timestamp) }}
    </button>
  </div>
</template>

<script>
import { datadogLogs as log } from "@datadog/browser-logs";
import EnsureCloud from "../../helpers/ensure-cloud";
import { formatTimeStamp as formatUtil } from "../../helpers/time";

export default {
  props: {
    timestamp: Number,
    replayId: String,
  },
  emits: ["commentCreated"],
  data() {
    return {
      timestampString: "",
      comment: "",
    };
  },
  mounted() {
    EnsureCloud();
  },
  methods: {
    formatTimestamp(timestamp) {
      return formatUtil(timestamp);
    },
    mounted() {
      EnsureCloud();
    },
    async createComment() {
      const newComment = await Cloud.commentCreate({
        timestamp: this.timestamp,
        description: this.comment,
        replayId: this.replayId,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (newComment) {
        this.$toast.info("Comment has been created");
        this.comment = "";
        this.$emit("commentCreated", newComment);
      }
    },
  },
};
</script>

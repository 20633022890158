<template>
  <MenuButton
    @click="copyLink()"
    class="mr-2 relative inline-flex items-center justify-between px-2 py-2 rounded-md text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white"
  >
    <LinkIcon class="mr-2 h-5 w-5" />
    Copy Link
  </MenuButton>
  <MenuVue as="span" class="-mr-px relative block">
    <MenuButton
      class="relative inline-flex items-center justify-between px-2 py-2 rounded-md text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white"
    >
      <CodeBracketIcon class="h-5 w-5 text-gray-400 mr-2" />
      Embed
      <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="mt-10 top-0 absolute left-1 -ml-1 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem
            v-slot="{ active }"
            v-for="mode in copyModes"
            :key="mode.id"
          >
            <div class="relative cursor-pointer">
              <a
                v-if="!mode.children"
                class="hover:bg-gray-300 hover:text-gray-800 flex items-center justify-between text-gray-700 px-4 py-2 text-sm"
                @click="copyEmbed(mode)"
              >
                {{ mode.name }}
              </a>
              <MenuVue v-else as="span" class="-mr-px relative block">
                <MenuButton
                  class="w-full hover:bg-gray-300 hover:text-gray-800 flex items-center justify-between text-gray-700 px-4 py-2 text-sm"
                >
                  {{ mode.name }}
                  <ChevronRightIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </MenuButton>
                <MenuItems
                  v-show="active"
                  static
                  class="top-0 left-32 absolute w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1">
                    <MenuItem
                      v-for="childMode in mode.children"
                      :key="childMode.id"
                    >
                      <div class="relative cursor-pointer">
                        <a
                          class="hover:bg-gray-300 hover:text-gray-800 flex items-center justify-between text-gray-700 px-4 py-2 text-sm"
                          @click="copyEmbed(mode, childMode)"
                        >
                          {{ childMode.name }}
                        </a>
                      </div>
                    </MenuItem>
                  </div>
                </MenuItems>
              </MenuVue>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </MenuVue>
</template>

<script>
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LinkIcon,
  CodeBracketIcon,
} from "@heroicons/vue/20/solid";
import {
  Menu as MenuVue,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

export default {
  components: {
    MenuVue,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChevronRightIcon,
    LinkIcon,
    CodeBracketIcon,
  },
  emits: ["copy-link"],
  data() {
    return {
      copyModes: [
        {
          id: "markdown",
          name: "Markdown",
          children: [
            {
              id: "gif",
              name: "GIF",
            },
            {
              id: "image",
              name: "Image",
            },
          ],
        },
        {
          id: "html",
          name: "HTML",
          children: [
            {
              id: "embed",
              name: "iFrame",
            },
            {
              id: "image",
              name: "Image",
            },
          ],
        },
      ],
    };
  },
  methods: {
    copyLink() {
      console.log("copy link");
      this.$emit("copy-link", {
        name: "link",
        id: "link",
        parentId: "link",
      });
    },
    copyEmbed(mode, childMode) {
      console.log("copy link", mode, childMode);

      const name = mode.name + (childMode ? `/${childMode.name}` : "");
      this.$emit("copy-link", {
        name,
        parentId: mode.id,
        childId: childMode?.id,
      });
    },
  },
};
</script>

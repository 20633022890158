<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="h-screen bg-slate-900"
    @mouseup="isResizing = false"
    @mousemove="resizePannels"
  >
    <main>
      <div>
        <div v-if="isLoading">
          <div class="p-20 sm:p-40">
            <Spinner :dark="true" />
          </div>
        </div>

        <div v-if="!isLoading">
          <!-- The Video -->
          <div
            class="md:fixed md:top-0 md:bottom-0 md:left-0 bg-slate-800 md:overflow-y-scroll scrollbar-hide"
            :style="{ width: `${videoWidth * 100}%` }"
          >
            <div
              :class="{
                '': !isEmbedded,
              }"
            >
              <div class="">
                <Disclosure
                  as="nav"
                  class="bg-gray-800"
                  v-slot="{ open }"
                  v-if="!isEmbedded"
                >
                  <div class="shadow border-b border-slate-600 bg-slate-700">
                    <div class="flex items-center justify-between h-12">
                      <div class="flex items-center justify-start h-12">
                        <div class="h-full flex bg-slate-800">
                          <a
                            href="/"
                            class="px-2 flex justify-center items-center bg-slate-900"
                          >
                            <img
                              class="h-7"
                              src="/images/td-logo-on-dark.png"
                              alt="TestDriver Logo"
                            />
                          </a>
                          <div
                            class="w-0 h-0 border-t-[24px] border-t-transparent border-l-[24px] border-l-slate-900 border-b-[24px] border-b-transparent"
                          ></div>
                          <a
                            href="/"
                            class="bg-slate-800 px-4 flex justify-center items-center"
                          >
                            <img
                              class="h-6"
                              src="/images/logo-on-dark@3x.png"
                              alt="Dashcam Logo"
                            />
                          </a>
                        </div>
                        <div
                          class="w-0 h-0 border-t-[24px] border-t-transparent border-l-[24px] border-l-slate-800 border-b-[24px] border-b-transparent"
                        ></div>
                      </div>
                      <div class="flex items-center">
                        <div class="hidden md:block">
                          <div class="ml-5 flex items-baseline space-x-4">
                            <a
                              v-for="item in navigation"
                              :key="item.name"
                              :href="item.href"
                              :class="[
                                item.current
                                  ? 'bg-blue-600 text-white'
                                  : 'text-gray-300 hover:bg-slate-700 hover:border-b-slate-800 hover:border-t-slate-600 hover:shadow hover:text-white',
                                'px-3 py-1 rounded-sm text-sm font-medium',
                              ]"
                              :aria-current="item.current ? 'page' : undefined"
                              >{{ item.name }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <div v-if="!isLoading" class="mr-2">
                        <div v-if="!user">
                          <span
                            class="relative z-0 inline-flex shadow-sm rounded-sm"
                          >
                            <a
                              href="/"
                              class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            >
                              Sign Up
                            </a>
                            <a
                              href="/"
                              class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            >
                              Log In
                            </a>
                          </span>
                        </div>
                        <div v-if="user">
                          <div class="hidden md:block">
                            <div class="ml-4 flex items-center md:ml-6">
                              <!-- Profile dropdown -->
                              <MenuVue as="div" class="ml-3 relative">
                                <div>
                                  <MenuButton
                                    class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                  >
                                    <span class="sr-only">Open user menu</span>
                                    <img
                                      class="h-8 w-8 rounded-full"
                                      :src="user.profile.picture"
                                      alt=""
                                    />
                                  </MenuButton>
                                </div>
                                <transition
                                  enter-active-class="transition ease-out duration-100"
                                  enter-from-class="transform opacity-0 scale-95"
                                  enter-to-class="transform opacity-100 scale-100"
                                  leave-active-class="transition ease-in duration-75"
                                  leave-from-class="transform opacity-100 scale-100"
                                  leave-to-class="transform opacity-0 scale-95"
                                >
                                  <MenuItems
                                    class="origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    <MenuItem
                                      v-for="item in userNavigation"
                                      :key="item.name"
                                      v-slot="{ active }"
                                    >
                                      <a
                                        :href="item.href"
                                        :class="[
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700',
                                        ]"
                                        >{{ item.name }}</a
                                      >
                                    </MenuItem>
                                  </MenuItems>
                                </transition>
                              </MenuVue>
                            </div>
                          </div>
                          <div class="-mr-2 flex md:hidden">
                            <!-- Mobile menu button -->
                            <DisclosureButton
                              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                              <span class="sr-only">Open main menu</span>
                              <Bars3Icon
                                v-if="!open"
                                class="block h-6 w-6"
                                aria-hidden="true"
                              />
                              <XMarkIcon
                                v-else
                                class="block h-6 w-6"
                                aria-hidden="true"
                              />
                            </DisclosureButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DisclosurePanel class="border-b border-gray-700 md:hidden">
                    <div class="px-2 py-3 space-y-1 sm:px-3">
                      <DisclosureButton
                        v-for="item in navigation"
                        :key="item.name"
                        as="a"
                        :href="item.href"
                        :class="[
                          item.current
                            ? 'bg-blue-600 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium',
                        ]"
                        :aria-current="item.current ? 'page' : undefined"
                        >{{ item.name }}</DisclosureButton
                      >
                    </div>
                    <div class="pt-4 pb-3 border-t border-gray-700">
                      <div class="flex items-center px-5">
                        <div class="flex-shrink-0">
                          <img
                            class="h-10 w-10 rounded-full"
                            :src="user.profile.picture"
                            alt=""
                          />
                        </div>
                        <div class="ml-3">
                          <div
                            class="text-base font-medium leading-none text-white"
                          >
                            {{ user.profile.email }}
                          </div>
                        </div>
                        <button
                          type="button"
                          class="ml-auto bg-gray-800 flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                          <span class="sr-only">View notifications</span>
                        </button>
                      </div>
                      <div class="mt-2 px-2 space-y-1">
                        <DisclosureButton
                          v-for="item in userNavigation"
                          :key="item.name"
                          as="a"
                          :href="item.href"
                          class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                          >{{ item.name }}</DisclosureButton
                        >
                      </div>
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              </div>
              <div>
                <div>
                  <div
                    v-if="errorType"
                    class="py-40 px-0 sm:px-40 md:px-20 lg:px-40 md:w-2/3 xl:w-1/2 mx-auto"
                  >
                    <div class="rounded-md bg-yellow-50 p-4">
                      <div v-if="errorType === 'notFound'" class="flex">
                        <div class="flex-shrink-0">
                          <ExclamationTriangleIcon
                            class="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-yellow-800">
                            There was a problem
                          </h3>
                          <div class="mt-2 text-sm text-yellow-700">
                            <p>
                              The resource does not exist. Please check the
                              replay id or share key again.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="errorType === 'notAllowed'"
                        class="flex flex-col items-center"
                      >
                        <div v-if="!accessRequestSent" class="w-full max-w-sm">
                          <h3 class="text-lg font-medium text-yellow-800 mb-2">
                            This Dash is private, but you can request access.
                          </h3>

                          <input
                            v-if="!user"
                            placeholder="Your Email"
                            required
                            v-model="requestEmail"
                            type="email"
                            name="invite-email"
                            id="invite-email"
                            :class="{ 'border-red-500': requestEmailError }"
                            class="flex-1 font-mono focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-sm border-gray-300 text-gray-500 mr-4"
                          />
                          <textarea
                            rows="6"
                            name="requestMessage"
                            v-model="requestMessage"
                            @keydown.stop=""
                            id="requestMessage"
                            class="mt-2 mb-2 block font-mono w-full h-24 rounded flex-1 p-2 border-gray-300"
                            placeholder="Message (optional)"
                          />
                          <div class="flex items-center justify-between">
                            <button
                              @click="requestAccess()"
                              type="button"
                              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Request Access
                            </button>
                          </div>
                          <p
                            v-if="requestEmailError"
                            class="text-red-500 text-xs italic pt-2"
                          >
                            {{ requestEmailError }}
                          </p>
                        </div>
                        <div v-else class="w-full max-w-sm">
                          <h3 class="text-lg font-medium text-yellow-800">
                            Request for access sent
                          </h3>
                          <div class="mt-2 text-sm text-yellow-700">
                            <p>
                              You'll get an email letting you know if your
                              request was approved
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="justify-between p-2 border-b border-b-slate-700 shadow"
                      v-if="!isEmbedded"
                    >
                      <div class="flex-1 min-w-0">
                        <div class="flex items-center">
                          <div class="flex-1">
                            <h2
                              class="text-xl font-bold leading-7 text-slate-400 sm:text2xl p-1.5 my-2"
                              :class="{
                                'rounded-md hover:ring-1 hover:ring-slate-600 cursor-text':
                                  canUpdateReplay,
                              }"
                              @click="editReplayTitle"
                              v-show="!isEditingTitle"
                            >
                              {{ replay.title }}
                            </h2>
                            <div class="" v-show="isEditingTitle">
                              <input
                                ref="replayInput"
                                id="replayInput"
                                name="replayInput"
                                type="text"
                                class="text-xl bg-slate-900 font-bold leading-7 text-slate-400 sm:text2xl p-1.5 my-2 shadow focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md"
                                v-model="editReplay.title"
                                @blur="saveReplayTitle"
                                @keyup.enter="saveReplayTitle"
                                @keydown.stop=""
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6 z-20 relative"
                        >
                          <div class="overflow-x-auto overscroll-contain">
                            <ApplicationTag
                              v-for="app in replay.apps"
                              :key="app"
                              :text="app"
                              :dark="true"
                              :icon="
                                replay.icons
                                  ? replay.icons.find(
                                      (icon) => icon.name === app
                                    )?.url
                                  : null
                              "
                              class="mr-2"
                            />
                          </div>
                          <div class="flex-1 flex-col text-right">
                            <ReplayShareMenu
                              :can-update-replay="canUpdateReplay"
                              :active-access-option="editReplay.access"
                              :access-invites="replay?.accessInvites"
                              :replay-id="replay?.id"
                              @access-option-selected="updateReplayAccess"
                              @copy-link="copyLink"
                              @replay-updated="updateReplay"
                            />
                            <button
                              type="button"
                              class="inline-flex items-center px-4 py-2 h-fit rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 ml-4"
                              @click="toggleReplayMeta()"
                              v-if="!showReplayMeta"
                            >
                              <ChevronDoubleLeftIcon class="w-5 h-5" />Show
                              Details
                            </button>
                            <MenuVue
                              as="div"
                              class="relative inline-block text-left"
                              v-if="canUpdateReplay || !replayIsInSameTeam"
                            >
                              <div>
                                <MenuButton
                                  class="rounded-full flex items-center px-4 py-2 ml-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-blue-900"
                                >
                                  <span class="sr-only">Open options</span>
                                  <EllipsisVerticalIcon
                                    class="h-5 w-5 mx-1"
                                    aria-hidden="true"
                                  />
                                </MenuButton>
                              </div>

                              <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                              >
                                <MenuItems
                                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                                >
                                  <div v-if="canUpdateReplay" class="py-1">
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        href="#"
                                        :class="[
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        ]"
                                        @click="openReplayMoveModal"
                                        >Move...</a
                                      >
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        href="#"
                                        :class="[
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'cursor-pointer block px-4 py-2 text-sm',
                                        ]"
                                        @click="openDeleteReplayModal"
                                        >Delete
                                      </a>
                                    </MenuItem>
                                  </div>
                                  <div v-if="!canUpdateReplay" class="py-1">
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        href="#"
                                        :class="[
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        ]"
                                        @click="openReplayCloneModal"
                                        >Clone...</a
                                      >
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </transition>
                            </MenuVue>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div :class="{ 'mt-2': !isEmbedded }">
                      <div
                        v-show="isConverting"
                        class="relative bg-gray-900 overflow-hidden"
                      >
                        <img
                          :src="replayScreenshotUrl"
                          :onload="onReplayImageLoad"
                          class="w-full mx-auto opacity-30"
                          ref="replayPreviewImage"
                        />
                        <div
                          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        >
                          <Spinner class="mx-auto" :dark="true" />
                          <div v-if="isConverting">
                            <div class="text-center text-lg text-gray-200 mt-3">
                              Converting...
                            </div>
                            <div class="text-center text-sm text-gray-400">
                              Your browser can not play this file. We are
                              converting it for you, or you can try another
                              browser. The video will play when conversion is
                              complete.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-show="
                          showReplayLoadingDashed || replay.status == 'draft'
                        "
                        class="border-2 border-slate-700 border-dashed m-2 rounded-lg p-12 text-center"
                      >
                        <Spinner :dark="true" />

                        <div>
                          <div class="text-center text-lg text-gray-200 mt-3">
                            Uploading...
                          </div>
                          <div class="text-center text-sm text-gray-400">
                            This video will play when uploading is complete.
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="replay.status == 'published'"
                        :class="{
                          'overflow-hidden': !isEmbedded,
                          'embedded-video-container':
                            isEmbedded && screenIsAtLeastMd,
                        }"
                      >
                        <a
                          :href="replay.shareLink"
                          target="_top"
                          v-if="isEmbedded"
                        >
                          <img
                            class="h-12 absolute top-4 right-4 z-10 opacity-0 bg-black rounded p-1 group-hover:opacity-70"
                            src="/images/logo-on-dark@3x.png"
                            alt="Workflow"
                          />
                        </a>
                        <div
                          id="videoWrap"
                          class="relative h-full"
                          :class="!isEmbedded ? 'mx-2' : ''"
                          v-if="!isConverting && replay.status == 'published'"
                        >
                          <div
                            class="play-overlay bg-black cursor-pointer group"
                            @click="!isPlaying && isEmbedded ? play() : null"
                          >
                            <font-awesome-icon
                              v-if="!isPlaying && isEmbedded"
                              icon="circle-play"
                              class="opacity-90 group-hover:opacity-100 absolute z-10 text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36"
                            />
                            <video
                              @keydown.stop=""
                              @timeupdate="onVideoTimeUpdate"
                              @canplay="onReplayVideoLoaded"
                              @play="setPlaying"
                              @pause="setPaused"
                              @seeking="onVideoSeeking"
                              @seeked="onVideoSeeked"
                              preload="auto"
                              ref="videoReplay"
                              id="videoReplay"
                              controls
                              class="opacity-100"
                              :class="{
                                'embedded-video-player':
                                  isEmbedded && screenIsAtLeastMd,
                                'opacity-50':
                                  !isPlaying && isEmbedded && screenIsAtLeastMd,
                                'group-hover:opacity-60':
                                  !isPlaying && isEmbedded && screenIsAtLeastMd,
                              }"
                              style="width: 100%; object-fit: contain"
                            >
                              <source
                                v-if="replayUrl"
                                :src="replayUrl"
                                @error="onVideoSourceLoadError"
                              />
                              <source
                                v-if="optimizedReplayUrl"
                                :src="optimizedReplayUrl"
                                @error="onOptimizedVideoSourceLoadError"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                      <div
                        class="flex justify-between items-center border-b border-b-slate-700 shadow p-2"
                        v-if="loadedReplayVideo && !isEmbedded"
                      >
                        <div class="">
                          <span class="isolate inline-flex rounded-sm shadow">
                            <button
                              type="button"
                              :class="[
                                videoPlaybackRate === 0.5
                                  ? activeButtonClass
                                  : inactiveButtonClass,
                                'relative inline-flex items-center rounded-l-sm px-4 py-2 text-sm font-medium',
                              ]"
                              @click="setVideoPlaybackRate(0.5)"
                            >
                              .5x
                            </button>
                            <button
                              type="button"
                              :class="[
                                videoPlaybackRate === 1.0
                                  ? activeButtonClass
                                  : inactiveButtonClass,
                                'relative inline-flex items-center rounded-l-sm px-4 py-2 text-sm font-medium',
                              ]"
                              @click="setVideoPlaybackRate(1.0)"
                            >
                              1x
                            </button>
                            <button
                              type="button"
                              :class="[
                                videoPlaybackRate === 2.0
                                  ? activeButtonClass
                                  : inactiveButtonClass,
                                'relative inline-flex items-center px-4 py-2 text-sm font-medium',
                              ]"
                              @click="setVideoPlaybackRate(2.0)"
                            >
                              2x
                            </button>
                            <button
                              type="button"
                              :class="[
                                videoPlaybackRate === 10.0
                                  ? activeButtonClass
                                  : inactiveButtonClass,
                                'relative inline-flex items-center px-4 py-2 text-sm font-medium',
                              ]"
                              @click="setVideoPlaybackRate(10.0)"
                            >
                              10x
                            </button>
                          </span>
                        </div>
                        <p class="text-xs text-slate-600">
                          <strong>ProTip:</strong> Pinch to zoom
                        </p>
                      </div>

                      <div
                        v-if="replay.errorText"
                        class="border-red-500 border bg-slate-900 text-red-500 font-mono p-4 rounded-lg mt-2 mx-2 flex items-start"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-bug"
                          class="text-red-500 w-5 mr-3 mt-1"
                        />
                        <div>{{ replay.errorText }}</div>
                      </div>

                      <div class="p-2">
                        <ReplayCommentCreate
                          v-if="user && !isEmbedded"
                          class="mb-4"
                          :replay-id="replay.id"
                          :timestamp="videoTime / 1000"
                          @comment-created="onCommentCreated"
                        />
                        <ReplayComments
                          v-if="loadedReplayVideo && !isEmbedded"
                          :comments="replay.comments"
                          :user="user"
                          @comment-deleted="onCommentDeleted"
                          @set-video-time="setVideoTime"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isResizable"
            class="fixed top-0 bottom-0 z-50 hover:cursor-col-resize select-none"
            :style="{ left: `${separatorLeft * 100}%` }"
            @mousedown="isResizing = true"
          >
            <div class="absolute top-0 -left-2 w-4 h-full"></div>
          </div>

          <div
            v-if="!errorType"
            class="md:fixed md:overflow-y-scroll md:top-0 md:right-0 md:bottom-0 md:pb-0 scrollbar-hide h-screen"
            :class="{
              'bg-slate-900 text-sm': !isEmbedded,
              'bg-black text-xs': isEmbedded,
            }"
            :style="{ width: `${metadataWidth * 100}%` }"
          >
            <div v-if="isLoadingLogs">
              <div class="p-20 sm:p-40">
                <Spinner :dark="true" />
              </div>
            </div>

            <div class="relative h-screen flex-col flex" v-if="!isLoadingLogs">
              <div
                class="bg-slate-900 flex flex-1 h-full flex-col border-l-black"
              >
                <div class="flex-col">
                  <div class="hidden sm:block">
                    <div class="border-b border-slate-800">
                      <nav
                        class="h-12 bg-slate-700 border-l border-l-slate-800 shadow border-b-slate-600 border-b select-none"
                        aria-label="Tabs"
                      >
                        <a
                          v-for="tab in tabs"
                          :key="tab.id"
                          @click="setTab(tab)"
                          :class="[
                            currentTab === tab.id
                              ? 'border-slate-300 text-slate-300'
                              : 'border-transparent text-slate-500 hover:text-slate-400 hover:border-b-slate-400',
                            'group inline-flex items-center border-b-2 xl:py-4 xl:px-4 h-12 px-4 text-sm font-medium cursor-pointer  border-r-slate-800 border-r',
                          ]"
                          :aria-current="
                            currentTab === tab.id ? 'page' : undefined
                          "
                        >
                          <font-awesome-icon
                            :icon="tab.icon"
                            :class="[
                              currentTab === tab.id
                                ? 'text-slate-300'
                                : 'text-slate-500 group-hover:text-slate-400',
                              '-ml-0.5 h-5 w-5',
                            ]"
                            aria-hidden="true"
                          />
                          <span class="hidden xl:block ml-2">{{
                            tab.name
                          }}</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>

                <!-- Tabs content -->
                <ReplayInfo
                  v-if="currentTab === tabs[0].id"
                  :replay="replay"
                  :can-update-replay="canUpdateReplay"
                  :is-loading="isLoading"
                  :error="error"
                  :jira-issue-lead-time="jiraIssueLeadTime"
                  :is-embedded="isEmbedded"
                  @update-replay="updateReplay"
                />
                <ReplayDevice
                  v-if="currentTab === tabs[1].id"
                  :replay="replay"
                />
                <ApplicationLogs
                  v-if="currentTab === tabs[2].id"
                  :is-loading-logs="isLoadingLogs"
                  :application-logs="applicationLogs"
                  :video-time="videoTime"
                  :is-playing="isPlaying"
                  @set-video-time="setVideoTime"
                  @manual-scroll="pause"
                />
                <WebLogs
                  v-if="currentTab === tabs[3].id"
                  :is-loading-logs="isLoadingLogs"
                  :web-logs="webLogs"
                  :video-time="videoTime"
                  :is-playing="isPlaying"
                  @set-video-time="setVideoTime"
                  @manual-scroll="pause"
                />
              </div>
            </div>
          </div>
          <a
            v-if="isEmbedded"
            class="fixed bottom-2 right-2 bg-white rounded-lg px-2 py-1 flex items-center hover:bg-white/90"
            target="_blank"
            :href="fullscreenLink"
          >
            <LinkIcon class="h-5 w-5 mx-1" />
            View Fullscreen
          </a>
        </div>
      </div>
    </main>
  </div>
  <DeleteReplayModal
    :show="openDeleteModal"
    @confirm-delete-replay="deleteReplay"
    @close-delete-modal="closeReplayDeleteModal"
  />
  <MoveReplayModal
    :show="openMoveReplayModal"
    @close-replay-move-modal="closeReplayMoveModal"
    @confirm-move-replay="updateReplayProject"
    :replay="replay"
  />
  <CloneReplayModal
    :show="openCloneReplayModal"
    @close-replay-clone-modal="closeReplayCloneModal"
    @confirm-clone-replay="cloneReplayIntoProject"
    :replay="replay"
  />
</template>

<script>
import { datadogLogs as log } from "@datadog/browser-logs";
import { nextTick, ref, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import moment from "moment";
// import PubNub from "pubnub";

// T-7188 All icons should use this package instead
import {
  EllipsisVerticalIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  ChevronDoubleLeftIcon,
  // ChatBubbleLeftIcon,
  // ChevronDoubleLeftIcon,
  // ChevronDoubleRightIcon,
} from "@heroicons/vue/20/solid";
import {
  Bars3Icon,
  LinkIcon,
  // AdjustmentsHorizontalIcon,
  // InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu as MenuVue,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import ApplicationTag from "../components/Library/ApplicationTag.vue";
import Spinner from "../components/Library/Spinner.vue";
import DeleteReplayModal from "../components/Library/DeleteReplayModal.vue";
import MoveReplayModal from "../components/Library/MoveReplayModal.vue";
import CloneReplayModal from "../components/Library/CloneReplayModal.vue";

import EnsureCloud from "../helpers/ensure-cloud";
import ReplayCommentCreate from "../components/Library/ReplayCommentCreate.vue";
import ReplayComments from "../components/Library/ReplayComments.vue";
import ApplicationLogs from "../components/Library/ApplicationLogs.vue";
import ReplayInfo from "../components/Library/ReplayInfo.vue";
import ReplayDevice from "../components/Library/ReplayDevice.vue";
import WebLogs from "../components/Library/WebLogs.vue";
import ReplayShareMenu from "../components/Library/ReplayShareMenu.vue";

const navigation = [
  {
    name: "Docs",
    href: "https://docs.dashcam.io",
    current: false,
  },
  {
    name: "Discord",
    href: "https://discord.gg/cWDFW8DzPm",
    current: false,
  },
  {
    name: "Support",
    href: "https://docs.dashcam.io",
    current: false,
  },
];

const userNavigation = [{ name: "Go to Dashboard", href: "/" }];

export default {
  components: {
    MenuVue,
    MenuButton,
    MenuItem,
    MenuItems,
    EllipsisVerticalIcon,
    ApplicationTag,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Bars3Icon,
    XMarkIcon,
    Spinner,
    ExclamationTriangleIcon,
    DeleteReplayModal,
    MoveReplayModal,
    CloneReplayModal,
    ReplayCommentCreate,
    ReplayComments,
    ChevronDoubleLeftIcon,
    LinkIcon,
    // ChevronDoubleLeftIcon,
    // ChevronDoubleRightIcon,
    // InformationCircleIcon,
    ReplayInfo,
    ReplayDevice,
    ApplicationLogs,
    WebLogs,
    ReplayShareMenu,
  },
  inject: ["auth"],
  setup() {
    const openDeleteModal = ref(false);
    const openMoveReplayModal = ref(false);
    const openCloneReplayModal = ref(false);
    const metaTags = reactive({
      title: "Replay - Dashcam",
      description: "Watch my desktop replay on Dashcam!",
      image: "",
    });
    const t0 = Date.now();

    useHead({
      // Can be static or computed
      title: computed(() => `Dashcam - ${metaTags.title}`),
      meta: [
        {
          name: "description",
          content: computed(() =>
            metaTags.description
              ? metaTags.description
              : "Watch my desktop replay on Dashcam!"
          ),
        },
        {
          name: "og:site_name",
          content: computed(() => "Dashcam"),
        },
        {
          name: "og:title",
          content: computed(() => metaTags.title),
        },
        {
          name: "og:image",
          content: computed(() => metaTags.image),
        },
        {
          name: "twitter:site",
          content: "dashcamapp",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: computed(() => metaTags.title),
        },
        {
          name: "twitter:description",
          content: computed(() =>
            metaTags.description
              ? metaTags.description
              : "Watch my replay on Dashcam!"
          ),
        },
        {
          name: "twitter:image",
          content: computed(() => metaTags.image),
        },
      ],
    });

    return {
      openDeleteModal,
      metaTags,
      navigation,
      openMoveReplayModal,
      openCloneReplayModal,
      userNavigation,
      t0,
    };
  },
  data() {
    return {
      user: null,
      replay: null,
      replayUrl: null,
      optimizedReplayUrl: null,
      isLoading: true,
      isConverting: false,
      isPlaying: false,
      loadedPreviewImage: false,
      loadedReplayVideo: false,
      triggeredVideoLoadError: false,
      publicShare: true,
      aditionalHeaders: {},
      loadAttempts: 0,
      errorType: "",
      error: "",
      accessRequestSent: false,
      isEditingTitle: false,
      editReplay: {
        title: "",
        access: "",
      },
      requestInProgress: false,
      viewedReplayHalfway: false,
      videoPlaybackRate: 1,
      currentTab: "info",
      activeButtonClass: "bg-blue-700 text-white border-blue-900",
      inactiveButtonClass:
        "bg-slate-700 border-slate-900 text-slate-400 hover:bg-slate-600",
      videoTime: 0,
      canUpdateReplay: false,
      showReplayMeta: true,
      tabs: [
        {
          baseName: "Info",
          name: "Info",
          id: "info",
          href: "#",
          icon: "fa-solid fa-info-circle",
        },
        {
          baseName: "Device",
          name: "Device",
          id: "device",
          href: "#",
          icon: "fa-solid fa-laptop",
        },
        {
          baseName: "Logs",
          name: "Logs",
          id: "application_logs",
          href: "#",
          icon: "fa-solid fa-terminal",
        },
        {
          baseName: "Chrome",
          name: "Chrome",
          id: "web_logs",
          href: "#",
          icon: "fa-brands fa-chrome",
        },
      ],
      windowWidth: window.innerWidth,
      isLoadingLogs: true,
      separatorLeft: 6 / 12,
      isResizing: false,
    };
  },
  computed: {
    videoWidth() {
      if (!this.showReplayMeta) return 1;
      if (this.isResizable) return this.separatorLeft;
      if (this.screenIsAtLeastMd) return 6 / 12;
      return 1;
    },
    metadataWidth() {
      if (this.isResizable) return 1 - this.separatorLeft;
      if (this.screenIsAtLeastMd) return 6 / 12;
      return 1;
    },
    isResizable() {
      return this.screenIsAtLeastMd;
    },
    screenIsAtLeastMd() {
      return this.windowWidth >= 768;
    },
    applicationLogs() {
      // eslint-disable-next-line no-shadow
      return this.renderLogs.filter(
        // eslint-disable-next-line no-shadow
        ({ log }) => log.type === "application" || log.type === "cli"
      );
    },
    applicationLogsLength() {
      return this.applicationLogs
        .map((appLog) => Object.values(appLog.lines))
        .flat()
        .flat().length;
    },
    webLogs() {
      // eslint-disable-next-line no-shadow
      return this.renderLogs.find(({ log }) => log.type === "web");
      // WebLog configs share a single file as opposed to application logs where they are separated
    },
    webLogsLength() {
      const countedTypes = [
        "LOG_EVENT",
        "LOG_ERROR",
        "NAVIGATION_COMPLETED",
        "SPA_NAVIGATION",
      ];
      if (!this.webLogs) return 0;
      return Object.values(this.webLogs.lines)
        .flat()
        .filter((event) => countedTypes.includes(event.type)).length;
    },
    fullscreenLink() {
      return this.$route.href.replace("embed=true", "");
    },
    isEmbedded() {
      return this.$route.query.embed === "true";
    },
    showReplayLoadingDashed() {
      return this.replay?.status !== "published" && !this.loadedPreviewImage;
    },
    showReplayPreview() {
      return this.replay?.status === "draft";
    },
    replayScreenshotUrl() {
      return `${import.meta.env.VITE_API_ENDPOINT}/replay/${
        this.replay.id
      }/screenshot?shareKey=${this.replay?.shareKey}`;
    },
    replayGifUrl() {
      return `${import.meta.env.VITE_API_ENDPOINT}/replay/${
        this.replay.id
      }/gif?shareKey=${this.replay?.shareKey}`;
    },
    totalLogCount() {
      return this.renderLogs.length;
    },
    renderLogs() {
      if (!this.replay) return [];
      const renderLogs = [];

      // eslint-disable-next-line no-shadow, consistent-return
      _.each(this.replay.logs, (log) => {
        let unparsedJsonLines = [];

        // log.lines may not exist yet
        if (log.lines && log.lines.length) {
          try {
            unparsedJsonLines = log.lines.split("\n");
          } catch (e) {
            return null;
          }

          // eslint-disable-next-line no-shadow
          const parsedJsonLines = _.map(unparsedJsonLines, (log, index) => {
            if (!log) {
              return null;
            }

            try {
              const json = JSON.parse(log);

              // make an id to use in vue render
              json.id = index;
              return json;
            } catch (e) {
              console.error(e);
              return null;
            }
          });

          // eslint-disable-next-line no-shadow
          const filteredJsonLines = _.filter(parsedJsonLines, (log) => log);

          renderLogs.push({ lines: filteredJsonLines, log });
        }
      });

      return renderLogs;
    },
    replayIsInSameTeam() {
      return this.replay?.project?.team?.id === this.user?.team?.id;
    },
    jiraIssueLeadTime() {
      if (this.replay?.jiraIssueLeadTime) {
        return moment
          .duration(Math.floor(this.replay.jiraIssueLeadTime), "minutes")
          .humanize();
      }

      return null;
    },
  },
  watch: {
    applicationLogsLength(applicationLogsLength) {
      const appLogsTab = this.tabs.find((tab) => tab.id === "application_logs");
      if (applicationLogsLength) this.setTab(this.tabs[2]);
      appLogsTab.name = this.getTabName(
        appLogsTab.baseName,
        applicationLogsLength
      );
    },
    webLogsLength(webLogsLength) {
      const webLogsTab = this.tabs.find((tab) => tab.id === "web_logs");
      if (webLogsLength && !this.applicationLogsLength) {
        this.setTab(this.tabs[3]);
      }
      webLogsTab.name = this.getTabName(webLogsTab.baseName, webLogsLength);
    },
  },
  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("resize", this.handleWindowResize);
    this.windowWidth = window.innerWidth;

    window.analytics?.page();
    await EnsureCloud();

    if (this.$store.state.token) {
      this.aditionalHeaders = this.$store.state.token
        ? {
            Authorization: `Bearer ${this.$store.state.token}`,
          }
        : {};

      this.user = await Cloud.whoami()
        .headers(this.aditionalHeaders)
        .tolerate((err) => {
          console.error(err);
        });

      // let pubnub = new PubNub({
      //   subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
      //   publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
      //   ssl: true,
      //   userId: this.user?.id,
      // });
      // pubnub.setToken(this.user.pubnub.token);

      // pubnub.addListener({
      //   message: (m) => {
      //     if (m.message.type == "replayPublished") {
      //       if (m.message.replay.id == this.replay.id) {
      //         // Don't overwrite the whole replay. Provide fields to update
      //         this.loadReplay({
      //           overWrite: false,
      //           fields: ["status"],
      //           trackedReplayView: true,
      //         });

      //         window.analytics?.track("Replay Load via Push Notification", {
      //           userId: this.user?.id,
      //           replay: this.replay.id,
      //         });
      //       } else {
      //         log.logger.warn(
      //           "Replay published message received for wrong replay"
      //         );
      //       }
      //     }
      //   },
      // });

      // pubnub.subscribe({
      //   channels: [this.user.pubnub.channels.userActivity],
      // });
    }
    await this.loadReplay({ overWrite: true });

    if (this.$route.query?.referrer === "desktop") {
      this.editReplayTitle(true);
      this.$refs.description?.showEditor();
    }
    await this.autoCopyToClipboard();
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    resizePannels(event) {
      if (!this.isResizing) return;
      if (event.clientX < 576) return;
      if (this.windowWidth - event.clientX < 450) return;
      this.separatorLeft = event.clientX / this.windowWidth;
    },
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    getTabName(name, number) {
      const n = number;
      if (typeof n !== "number" || Math.round(n) === 0) return name;
      return `${name} (${Math.round(n).toLocaleString()})`;
    },
    setPlaying() {
      this.isPlaying = true;
      window.analytics?.track("Video is playing");
    },
    setPaused() {
      window.analytics?.track("Video is paused");
      this.isPlaying = false;
    },
    onVideoSeeking() {
      window.analytics?.track("Video is being seeked");
    },
    onVideoSeeked() {
      window.analytics?.track("Video is seeked");
    },
    play() {
      this.$refs.videoReplay.play();
    },
    pause() {
      this.$refs.videoReplay.pause();
      this.setPaused();
    },
    togglePlay() {
      if (this.isPlaying) this.pause();
      else this.play();
    },
    setTab(tab) {
      this.currentTab = tab.id;
    },
    openDeleteReplayModal() {
      this.openDeleteModal = true;
    },
    closeReplayDeleteModal() {
      this.openDeleteModal = false;
    },
    openReplayMoveModal() {
      this.openMoveReplayModal = true;
    },
    openReplayCloneModal() {
      this.openCloneReplayModal = true;
    },
    closeReplayMoveModal() {
      this.openMoveReplayModal = false;
    },
    closeReplayCloneModal() {
      this.openCloneReplayModal = false;
    },
    setVideoPlaybackRate(rate) {
      this.$refs.videoReplay.playbackRate = rate;
      this.videoPlaybackRate = rate;
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async loadReplay({ overWrite, fields, trackedReplayView = false }) {
      this.loadAttempts += 1;

      const replayData = await Cloud.replayGet
        .with({
          ...(this.$route.params.replayId && {
            replayId: this.$route.params.replayId,
          }),
          ...(this.$route.query.share && {
            shareKey: this.$route.query.share,
          }),
          ...(this.$route.params.shareKey && {
            shareKey: this.$route.params.shareKey,
          }),
        })
        .headers(this.aditionalHeaders)
        .tolerate((err) => {
          log.logger.error(err);
          log.logger.error(err.exit);
        });

      if (replayData) {
        if (this.replay && !overWrite) {
          // Fields need to be provided to know which keys to update
          if (!fields.length) return;

          this.replay = _.assign(
            this.replay,
            _.pick(replayData.replay, fields)
          );
        } else {
          this.replay = replayData.replay;
        }

        this.canUpdateReplay = replayData.canUpdateReplay;
      }

      this.isLoading = false;

      if (replayData.notFound) {
        this.errorType = "notFound";
        this.showReplayMeta = false;
        window.analytics?.track("Resource not found", {
          userId: this.user?.id,
        });
        log.logger.error("Replay.vue resource not found", {
          replay: this.replay,
          userId: this.user?.id,
        });
      } else if (replayData.notAllowed) {
        this.errorType = "notAllowed";
        this.showReplayMeta = false;
      } else {
        if (!trackedReplayView) {
          window.analytics?.track("Replay View", {
            userId: this.user?.id,
            canUpdateReplay: this.canUpdateReplay,
            replayId: this.replay?.id,
          });

          log.logger.info("Replay.vue Replay View", {
            replay: this.replay,
            canUpdateReplay: this.canUpdateReplay,
          });
        }

        this.metaTags.title = this.replay.title;
        this.metaTags.description = this.replay.description;
        this.metaTags.image = this.replayGifUrl;
        this.editReplay.access = this.replay.access;

        // tell prenderScrollingLogsSize it's ok
        window.prenderScrollingLogsSizeReady = true;

        if (this.replay.status === "published") {
          // fetch log files

          if (this.replay.logs?.length) {
            this.replay.logs.forEach(async (logItem) => {
              const dl = await Cloud.logDownload({
                logId: logItem.id,
              }).headers(this.aditionalHeaders);

              // eslint-disable-next-line no-param-reassign
              logItem.lines = await fetch(dl.url).then((res) => res.text());
              this.isLoadingLogs = false;
            });
          } else {
            this.isLoadingLogs = false;
          }

          await Cloud.replayVideoRedirect
            .with({
              replayId: this.replay?.id,
              shareKey: this.replay?.shareKey,
            })
            .headers(this.aditionalHeaders)
            .tolerate((err) => {
              log.logger.error("replayVideoRedirect error", err);
            })
            .then((res) => {
              [this.optimizedReplayUrl, this.replayUrl] = res;

              nextTick(() => {
                this.$refs.videoReplay.currentTime = this.$route.query.timestamp
                  ? +this.$route.query.timestamp / 1000
                  : 0;
              });
            });
        } else {
          // This is a backup in case pubnub is down or doesn't work
          setTimeout(() => {
            // Don't overwrite the whole replay. Provide fields to update
            this.loadReplay({
              overWrite: false,
              fields: ["status", "logs"],
              trackedReplayView: true,
            });
          }, 5000);
          window.analytics?.track("Replay.vue Replay Unpublished", {
            userId: this.user?.id,
            canUpdateReplay: this.canUpdateReplay,
          });
          log.logger.info("Replay.vue Trying To Load Unpublished Replay", {
            replay: this.replay,
          });
        }
      }
    },
    onReplayVideoLoaded() {
      this.loadedReplayVideo = true;

      window.analytics?.track(
        `${this.isEmbedded ? "Embedded " : ""}Video Loaded`,
        {
          replay: this.replay,
          user: this.user,
        }
      );

      // eslint-disable-next-line no-undef
      if (this.isEmbedded && Intercom) {
        // eslint-disable-next-line no-undef
        Intercom("update", { hide_default_launcher: true });
      }

      this.videoPlaybackRate = this.$refs.videoReplay?.playbackRate;
    },
    async onVideoSourceLoadError(err) {
      log.logger.warn("Main Video Load Source Error", err);
      window.analytics?.track("Main Video Load Source Error", {
        replay: this.replay,
        user: this.user,
        err,
      });

      if (this.triggeredVideoLoadError) return;
      this.triggeredVideoLoadError = true;

      // Don't proceed further if replay video has loaded
      if (this.loadedReplayVideo) {
        log.logger.info(
          "Replay video is already loaded so better to wait till it plays",
          err
        );
        window.analytics?.track(
          "Replay video is already loaded so better to wait till it plays",
          {
            replay: this.replay,
            user: this.user,
            err,
          }
        );

        return;
      }

      if (this.replay.hasOptimizedVideo) {
        log.logger.warn("Has optimized video");
        window.analytics?.track(
          "Main video is failed to load so we try with the optimized video",
          {
            replay: this.replay,
            user: this.user,
          }
        );

        // Retry -> Maybe a temporary error
        await this.loadReplay({
          overWrite: false,
          fields: ["id", "videoUrl"],
          trackedReplayView: true,
        });
      } else {
        log.logger.warn(
          "Optimized video has not been created yet so we poll the status until it is created or for 15 minutes"
        );
        window.analytics?.track(
          "Optimized video has not been created yet so we poll the status until it is created or for 15 minutes",
          {
            replay: this.replay,
            user: this.user,
          }
        );

        this.isConverting = true;
        await this.checkVideoConvertStatus();
      }
    },

    onOptimizedVideoSourceLoadError(err) {
      log.logger.warn("Optimized Video Load Source Error", err);
      window.analytics?.track("Optimized Video Load Source Error", {
        replay: this.replay,
        user: this.user,
        err,
      });
    },

    async checkVideoConvertStatus(attempt = 1) {
      // Don't proceed further if replay video has loaded
      if (!this.loadedReplayVideo) {
        log.logger.warn(`Checking conversion status. Attempt ${attempt}`);

        const updatedReplay = await Cloud.replayConvertStatus
          .with({
            replayId: this.replay.id,
          })
          .headers(this.aditionalHeaders)
          .tolerate((err) => {
            log.logger.error("replayVideoCheck error", err);
          });

        if (updatedReplay && updatedReplay.hasOptimizedVideo) {
          log.logger.warn(
            "Polling the status has finished, optimized video is finally created"
          );
          window.analytics?.track(
            "Polling the status has finished, optimized video is finally created",
            {
              replay: this.replay,
              user: this.user,
            }
          );

          // Update the replay
          this.isConverting = false;
          await this.loadReplay({
            overwrite: false,
            fields: ["hasOptimizedVideo"],
          });
        } else {
          // If replay was created < 15 min ago, we should keep retrying
          const now = moment();
          const replayCreateTime = this.replay.createdAt;
          const duration = moment.duration(now.diff(replayCreateTime));
          if (duration.asMinutes() < 15) {
            // Retry after 5 seconds
            setTimeout(() => {
              this.checkVideoConvertStatus(attempt + 1);
            }, 5000);
          }
        }
      }
    },
    onReplayImageLoad() {
      // Ensure the image is actually loaded
      if (
        this.$refs.replayPreviewImage?.complete &&
        this.$refs.replayPreviewImage?.naturalWidth
      ) {
        this.loadedPreviewImage = true;
      }
    },
    handleKeyDown(event) {
      if (event.code === "Space") {
        this.togglePlay();
      }
    },
    async onVideoTimeUpdate() {
      this.videoTime = (this.$refs.videoReplay?.currentTime || 0) * 1000;

      if (this.viewedReplayHalfway) return;
      if (this.canUpdateReplay) return;

      // prevent sending emails to admins
      if (this.user?.profile?.email.indexOf("@dashcam.io") > -1) {
        console.info("Refusing to send view notification email because admin");
        return;
      }
      if (this.user?.profile?.email.indexOf("@replayable.io") > -1) {
        console.info("Refusing to send view notification email because admin");
        return;
      }
      if (
        this.$refs.videoReplay?.currentTime >
        0.5 * (this.$refs.videoReplay?.duration ?? 0)
      ) {
        // This should only happen once: the first time this condition is true
        // In other instances, the function returns early
        this.viewedReplayHalfway = true;

        log.logger.info("Send Replay Viewed Notification", {
          replay: this.replay,
          user: this.user,
        });

        window.analytics?.track("Send Replay Viewed Notification", {
          replay: this.replay,
          user: this.user,
        });

        await Cloud.replayViewNotification
          .with({
            replayId: this.replay.id,
          })
          .headers(this.aditionalHeaders)
          .switch({
            error: (err) => {
              log.logger.error("Send replay notification error", err);
            },
            success: () => {
              log.logger.info("Sent replay notification", {
                replay: this.replay,
                user: this.user,
              });
            },
          });
      }
    },
    dateDisplay(date) {
      return moment(date).calendar(null, {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        lastWeek: "dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
      });
    },
    trackShare(format) {
      window.analytics?.track("Share Copied", {
        format,
        userId: this.user?.id,
        replayId: this.replay?.id,
        canUpdateReplay: this.canUpdateReplay,
      });
    },
    async editReplayTitle(selectText) {
      if (!this.canUpdateReplay) return;
      this.isEditingTitle = true;

      // Set the initial value to the replay title's value
      this.editReplay.title = this.replay.title;

      await nextTick();
      // Focus input field
      this.$refs.replayInput.focus();

      if (selectText) {
        this.$refs.replayInput.select();
        // Delete the query param so that it only works on the first load
        // On refresh, it should not be focused anymore
        // this.$router.replace({ "query.referrer": null });
        this.$router.replace({
          query: {
            share: this.$route.query?.share,
            referrer: undefined,
          },
        });
      }
    },
    async saveReplayTitle() {
      this.isEditingTitle = false;

      // This function is called on blur or on pressing `Enter`
      // Prevent sending multiple requests in case both events are dispatched at the same time
      if (this.requestInProgress) return;

      // If there's no changes to the title don't send a request
      if (this.editReplay.title === this.replay.title) return;

      this.requestInProgress = true;

      const previousTitle = this.replay.title;

      // Optimistic update
      this.replay.title = this.editReplay.title;

      await Cloud.replayUpdate
        .with({
          replayId: this.replay.id,
          replay: {
            title: this.editReplay.title,
          },
        })
        .headers(this.aditionalHeaders)
        .switch({
          error: (err) => {
            log.logger.error("replayUpdate error", err);

            // Dealing with specific error cases
            if (err.responseInfo.statusCode === 401) {
              log.logger.error("replayUpdate error. Unauthorized", {
                err,
              });
            }

            // Revert the optimistic update
            this.replay.title = previousTitle;
            this.$toast.open({
              message: "Failed to Update Replay Title",
              type: "error",
            });
          },
          success: (updatedReplay) => {
            const keysToUpdate = ["title", "updatedAt"];
            this.replay = _.assign(
              this.replay,
              _.pick(updatedReplay, keysToUpdate)
            );
            log.logger.info("Updated Replay", {
              replay: this.replay,
            });
          },
        });

      this.requestInProgress = false;
    },
    async updateReplayAccess(accessValue) {
      // Don't send a request if the value hasn't changed
      if (this.replay.access === accessValue) return;

      await Cloud.replayUpdate
        .with({
          replayId: this.replay.id,
          replay: {
            access: accessValue,
          },
        })
        .headers(this.aditionalHeaders)
        .switch({
          error: (err) => {
            log.logger.error("Replay access update error", err);
            this.$toast.open({
              message: "Failed to Update Replay access",
              type: "error",
            });
          },
          success: (updatedReplay) => {
            log.logger.info("Updated Replay access", {
              replayId: updatedReplay.id,
              access: updatedReplay.access,
            });

            this.replay.access = updatedReplay.access;
            this.editReplay.access = updatedReplay.access;

            this.$toast.open({
              message: "Replay access updated",
              type: "success",
            });
          },
        });
    },
    async deleteReplay() {
      this.closeReplayDeleteModal();

      await Cloud.replayDelete
        .with({
          replayId: this.replay.id,
        })
        .headers(this.aditionalHeaders)
        .switch({
          error: (err) => {
            log.logger.error("replayDelete error", err);
            this.$toast.open({
              message: "Failed to Delete Replay",
              type: "error",
            });
          },
          success: () => {
            this.$toast.open({
              message: "Replay Deleted",
              type: "success",
            });

            this.$router.push({
              name: "replays",
              params: { projectId: this.replay.project.id },
            });
          },
        });
    },
    async updateReplayProject(project) {
      this.closeReplayMoveModal();

      await Cloud.replayMove
        .with({
          replayId: this.replay.id,
          projectId: project?.id,
        })
        .headers(this.aditionalHeaders)
        .switch({
          error: (err) => {
            log.logger.error("updateReplayProject error", err);
            this.$toast.open({
              message: "Failed to Move Replay",
              type: "error",
            });
          },
          success: (updatedReplay) => {
            this.$toast.open({
              message: "Successfully Moved Replay",
              type: "success",
            });
            // Update the replay with the new project
            this.replay = updatedReplay;
            console.log("Updated Replay", {
              replay: this.replay,
            });
          },
        });
    },
    async cloneReplayIntoProject(project) {
      this.closeReplayCloneModal();

      await Cloud.replayClone
        .with({
          replayId: this.replay.id,
          projectId: project?.id,
        })
        .headers(this.aditionalHeaders)
        .switch({
          error: (err) => {
            log.logger.error("cloneReplayProject error", err);
            this.$toast.open({
              message: "Failed to Clone Replay",
              type: "error",
            });
          },
          success: (clonedReplay) => {
            this.$toast.open({
              message: "Successfully Cloned Replay",
              type: "success",
            });

            window.open(`${clonedReplay.shareLink}`, "_blank");
          },
        });
    },
    async autoCopyToClipboard() {
      const { path, query } = this.$route;
      if (!query.referrer) return;
      try {
        await navigator.clipboard.writeText(`${window.location.host}${path}`);
        this.$toast.success("Link copied to clipboard");
      } catch (e) {
        log.logger.error("autoCopyToClipboard error", { e });
      }
    },
    copyLink(copyMode) {
      let copyText = "";
      if (copyMode.parentId === "link") {
        copyText = this.replay.shareLink;
      } else if (copyMode.parentId === "markdown") {
        if (copyMode.childId === "gif") {
          copyText = this.replay.gifMarkdown;
        } else if (copyMode.childId === "image") {
          copyText = this.replay.imageMarkdown;
        }
      } else if (copyMode.parentId === "html") {
        if (copyMode.childId === "embed") {
          copyText = this.replay.embed;
        } else if (copyMode.childId === "image") {
          copyText = this.replay.imageHtml;
        }
      }

      if (copyText) {
        this.$copyText(copyText).then(
          () => {
            this.$toast.open({
              message: "Copied To Clipboard!",
              type: "info",
            });

            log.logger.info("Copied Replay info", {
              replay: this.replay,
              canUpdateReplay: this.canUpdateReplay,
            });
          },
          (e) => {
            log.logger.error("Problem copying Replay info to clipboard", {
              e,
            });
            this.$toast.open({
              message: "There was a problem copying Replay info to clipboard",
              type: "error",
            });
          }
        );
      }
    },
    onCommentCreated(comment) {
      this.replay.comments.push(comment);
    },
    onCommentDeleted(comment) {
      this.replay.comments = this.replay.comments.filter(
        (c) => c.id !== comment.id
      );
    },
    setVideoTime(time) {
      this.$refs.videoReplay.currentTime = time / 1000;
    },
    updateReplay({ replay, fields }) {
      if (!fields) {
        this.replay = replay;
      } else {
        this.replay = _.assign(this.replay, _.pick(replay, fields));
      }
    },
    async requestAccess() {
      if (!this.user) {
        if (!this.requestEmail) {
          this.requestEmailError = "Email is required";
          return;
        }

        if (
          // eslint-disable-next-line no-useless-escape
          !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
            this.requestEmail
          )
        ) {
          this.requestEmailError = "Email is invalid";
          return;
        }
      }

      this.requestEmailError = "";

      const result = await Cloud.replayAccessRequest({
        ...(this.$route.params.replayId && {
          replayId: this.$route.params.replayId,
        }),
        ...(this.$route.query.share && {
          shareKey: this.$route.query.share,
        }),
        ...(this.$route.params.shareKey && {
          shareKey: this.$route.params.shareKey,
        }),
        requestEmail: this.requestEmail,
        requestMessage: this.requestMessage,
      })
        .headers(this.aditionalHeaders)
        .tolerate((err) => {
          this.accessRequestSent = false;
          log.logger.error(err);
        });

      if (result) {
        this.accessRequestSent = true;
        this.requestEmail = "";
        this.requestMessage = "";
      }
    },
  },
};
</script>

<style>
.embedded-video-container {
  width: 100%;
  height: 100%;
  position: absolute;
}
.embedded-video-player {
  position: absolute;
  height: 100vh;
}
iframe {
  display: none;
}
.vue-recycle-scroller__item-wrapper {
  overflow-x: hidden;
}
.vue-recycle-scroller__item-wrapper:hover {
  overflow-x: visible;
}
</style>

<template>
  <div class="loader w-full">
    <div class="loaderBar"></div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.loader {
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  padding: 2px;
}
.loader .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(167, 22, 22, 1) 44%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  25% {
    left: 0%;
    right: 65%;
    width: 35%;
  }
  75% {
    right: 0%;
    left: 65%;
    width: 35%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
</style>

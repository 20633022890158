<template>
  <div class="flex-col">
    <div class="lg:col-span-1 lg:col-start-3" v-if="replay">
      <div class="overflow-hidden" v-if="!isLoading && !error">
        <ul
          role="list"
          class="divide-y divide-slate-800 border-b border-gray-800 bg-slate-900"
        >
          <li class="p-4" v-if="replay.user?.profile && !shareKeySupplied">
            <div class="mt-2 flex items-center text-gray-500">
              <img
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 rounded-full"
                aria-hidden="true"
                :src="replay.user?.profile.picture"
              />
              <h6 class="text-slate-600 font-mono">Author</h6>
            </div>

            <div class="mt-2 flex items-center text-gray-500">
              <div class="font-mono mt-2 text-slate-300">
                {{ replay.user?.profile.email }}
              </div>
            </div>
          </li>
          <li class="p-4">
            <div class="mt-2 flex items-center text-gray-500">
              <CalendarIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <h6 class="text-slate-600 font-mono">Created</h6>
            </div>
            <div class="font-mono mt-2 text-slate-300">
              {{ dateDisplay(replay.createdAt) }}
            </div>
          </li>
          <li class="p-4">
            <div class="mt-2 flex items-center text-gray-500">
              <FolderIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <h6 class="text-slate-600 font-mono">Project</h6>
            </div>

            <div class="font-mono mt-2 text-slate-300">
              {{ replay?.project?.name }}
            </div>
          </li>
          <li
            v-if="
              replay.project?.team?.atlassian?.cloudUrl && replay.jiraIssueId
            "
            class="p-4"
          >
            <div class="mt-2 flex items-center text-gray-500">
              <font-awesome-icon
                class="w-5 h-5 mt-0.5 text-gray-400 mr-2"
                icon="fa-brands fa-jira"
              />
              <h6 class="text-slate-600 font-mono">Jira Issue</h6>
            </div>

            <a
              :href="`${replay.project?.team?.atlassian?.cloudUrl}/browse/${replay.jiraIssueKey}`"
              target="_blank"
              class="text-blue-500 hover:text-blue-700"
              rel="noopener noreferrer"
            >
              <div class="font-mono mt-2 text-slate-300 break-words">
                {{
                  `${replay.project?.team?.atlassian?.cloudUrl}/browse/${replay.jiraIssueKey}`
                }}
              </div>
            </a>
            <div
              v-if="jiraIssueLeadTime"
              class="font-mono mt-2 text-slate-300 break-words"
            >
              Lead Time: {{ jiraIssueLeadTime }}
            </div>
          </li>

          <li class="p-4">
            <div class="mt-2 flex items-center text-gray-500">
              <font-awesome-icon
                class="w-5 h-5 mt-0.5 text-gray-400 mr-1.5"
                icon="fa-brands fa-markdown"
              />
              <h6 class="text-slate-600 font-mono">Markdown</h6>
            </div>

            <div class="mt-2 text-slate-300">
              <MarkdownEditor
                ref="description"
                :can-edit="canUpdateReplay"
                :text="replay?.description"
                @save="saveReplayDescription"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { datadogLogs as log } from "@datadog/browser-logs";
import { CalendarIcon, FolderIcon } from "@heroicons/vue/20/solid";
import MarkdownEditor from "./MarkdownEditor.vue";

export default {
  components: {
    CalendarIcon,
    FolderIcon,
    MarkdownEditor,
  },
  props: [
    "replay",
    "isLoading",
    "error",
    "jiraIssueLeadTime",
    "isEmbedded",
    "canUpdateReplay",
  ],
  emits: ["updateReplay"],
  computed: {
    shareKeySupplied() {
      return this.$route.query.share || this.$route.params.shareKey;
    },
  },
  methods: {
    dateDisplay(date) {
      return moment(date).calendar(null, {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        lastWeek: "dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
      });
    },
    async saveReplayDescription(input) {
      await Cloud.replayUpdate
        .with({
          replayId: this.replay.id,
          replay: {
            description: input,
          },
        })
        .headers({ Authorization: `Bearer ${this.$store.state.token}` })
        .switch({
          error: (err) => {
            log.logger.error("replayUpdate error", err);

            // Dealing with specific error cases
            if (err.responseInfo.statusCode === 401) {
              log.logger.error("replayUpdate error. Unauthorized", {
                err,
              });
            }

            this.$toast.open({
              message: "Failed to Update Replay Description",
              type: "error",
            });
          },
          success: (updatedReplay) => {
            const keysToUpdate = ["description", "updatedAt"];
            // eslint-disable-next-line vue/no-mutating-props
            const newReplay = _.assign(
              this.replay,
              _.pick(updatedReplay, keysToUpdate)
            );
            this.$emit("updateReplay", newReplay);
            // eslint-disable-next-line sonarjs/no-duplicate-string
            log.logger.info("Updated Replay", {
              replay: this.replay,
            });
          },
        });
    },
  },
};
</script>

<template>
  <div
    v-for="comment in comments"
    :key="comment.id"
    class="sm:text-sm my-2 flex items-center justify-between"
  >
    <div>
      <div class="flex items-center">
        <img
          class="h-10 w-10 rounded-full mr-2"
          :src="comment.user.profile.picture"
          alt=""
        />
        <div class="flex flex-col">
          <div class="font-normal mr-2 text-slate-500">
            {{ comment.user.profile.name }}

            <span
              class="cursor-pointer select-none text-slate-400 hover:underline"
              @click="setVideoTime(comment.timestamp * 1000)"
              >(at {{ formatTimeStamp(comment.timestamp) }})</span
            >
          </div>
          <div class="text-slate-600 text-xs">
            {{ formatAgo(comment.createdAt) }}
          </div>
        </div>
      </div>
      <div class="text-slate-400 text-md ml-12 mb-6 font-mono">
        {{ comment.description }}
      </div>
    </div>
    <button
      v-if="user?.id === comment.user.id"
      @click.prevent="deleteComment(comment.id)"
      type="button"
      class="inline-flex items-center gap-x-1.5 rounded-md bg-slate-700 py-1.5 px-2.5 text-sm font-semibold text-slate-400 shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 border border-slate-900"
    >
      <TrashIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
      Remove
    </button>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/solid";
import { datadogLogs as log } from "@datadog/browser-logs";
import moment from "moment";
import EnsureCloud from "../../helpers/ensure-cloud";
import { formatTimeStamp as formatUtil } from "../../helpers/time";

export default {
  components: {
    TrashIcon,
  },
  props: {
    comments: Array,
    user: Object,
  },
  emits: ["commentDeleted", "setVideoTime"],
  async mounted() {
    EnsureCloud();
  },
  methods: {
    formatTimeStamp(timestamp) {
      return formatUtil(timestamp);
    },
    formatAgo(timestamp) {
      return moment(timestamp).fromNow();
    },
    async deleteComment(id) {
      await Cloud.commentDelete({
        id,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .switch({
          error: (err) => {
            log.logger.error("commentDelete error", err);
            this.$toast.open({
              message: "Failed to Delete Comment",
              type: "error",
            });
          },
          success: async () => {
            this.$toast.info("Comment has been deleted");
            this.$emit("commentDeleted", { id });
          },
        });
    },
    setVideoTime(time) {
      this.$emit("setVideoTime", time);
    },
  },
};
</script>

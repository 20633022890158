<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <a
    class="relative inline-flex items-center rounded-full border px-3 py-0.5 text-sm"
    :class="{
      ' border-slate-900 bg-slate-800': dark,
      'border-gray-300': !dark,
    }"
  >
    <span class="flex items-center justify-center mr-1 w-5 h-6">
      <img v-if="icon" :src="icon" :alt="text" class="h-5 w-5" />
      <span v-if="!icon" class="h-5 w-5">
        <span
          class="h-1.5 w-1.5 rounded-full block mt-1.5 ml-1.5"
          aria-hidden="true"
          :style="{ backgroundColor: stringToColor(text) }"
        />
      </span>
    </span>
    <span
      class="font-medium"
      :class="{ 'text-slate-400': dark, 'text-gray-900': !dark }"
      >{{ text }}</span
    >
  </a>
</template>

<script>
export default {
  props: {
    text: String,
    icon: String,
    dark: Boolean,
  },
  methods: {
    stringToColor(str) {
      if (str) {
        let hash = 0;
        for (let i = 0; i < str.length; i += 1) {
          // eslint-disable-next-line no-bitwise
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = "#";
        for (let i = 0; i < str.length; i += 1) {
          // eslint-disable-next-line no-bitwise
          const value = (hash >> (i * 8)) & 0xff;
          colour += `00${value.toString(16)}`.substr(-2);
        }
        return colour;
      }
      return "#000000";
    },
  },
};
</script>

<template>
  <form @submit.prevent="emitSubmit" @change="changeTab">
    <div class="overflow-hidden">
      <div>
        <TabGroup :selected-index="selectedTab">
          <TabPanels class="mt-2">
            <TabPanel class="-m-0.5 rounded-lg">
              <Markdown
                :source="preserveWhitespace(tmpText)"
                :linkify="true"
                :breaks="true"
                :typographer="true"
                v-if="text"
                class="font-mono prose mx-px mt-px p-2 text-sm leading-5 text-slate-300 break-words"
              />
            </TabPanel>
            <TabPanel class="p-0.5 -m-0.5 rounded-lg">
              <label for="Markdown" class="sr-only">markdown</label>
              <div>
                <textarea
                  :readonly="!canEdit"
                  rows="6"
                  name="Markdown"
                  :value="tmpText"
                  @change="setText"
                  @keydown.stop=""
                  id="Markdown"
                  style="background-color: #060c18"
                  class="block font-mono w-full h-32 rounded flex-1 h-10 outline-none p-2 opacity-1 shadow shadow-[inset_0_1px_2px_rgba(0,0,0,0.3)] border-slate-900 text-slate-400 rounded-r-none"
                  placeholder="Add your description, stacktrace, or logs. Supports markdown..."
                />
              </div>
            </TabPanel>
          </TabPanels>

          <div class="flex justify-between mt-2">
            <TabList class="flex items-center">
              <Tab @click="isEditing = false" class="mr-2">
                <div
                  :class="[
                    !isEditing
                      ? 'text-slate-700 bg-slate-400 hover:bg-slate-300'
                      : 'hover:text-slate-400 text-slate-400',
                    'inline-flex items-center gap-x-1.5 rounded-md bg-slate-700 py-1.5 px-2.5 text-sm font-semibold text-slate-400 shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 border border-slate-900',
                  ]"
                >
                  <span v-if="canEdit">Preview</span>
                  <span v-if="!canEdit">Markdown</span>
                </div>
              </Tab>

              <Tab @click="isEditing = true">
                <div
                  :class="[
                    isEditing
                      ? 'text-slate-700 bg-slate-400 hover:bg-slate-300'
                      : 'hover:text-slate-400 text-slate-400',
                    'inline-flex items-center gap-x-1.5 rounded-md bg-slate-700 py-1.5 px-2.5 text-sm font-semibold  shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 border border-slate-900',
                  ]"
                >
                  <span v-if="canEdit">Edit</span>
                  <span v-if="!canEdit">View Source</span>
                </div>
              </Tab>
              <!-- These buttons are here simply as examples and don't actually do anything. -->
              <div
                v-if="false && isEditing === 0"
                class="ml-auto flex items-center space-x-5"
              >
                <div class="flex items-center">
                  <button
                    type="button"
                    class="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span class="sr-only">Insert link</span>
                    <LinkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div class="flex items-center">
                  <button
                    type="button"
                    class="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span class="sr-only">Insert code</span>
                    <CodeBracketIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div class="flex items-center">
                  <button
                    type="button"
                    class="-m-2.5 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
                  >
                    <span class="sr-only">Mention someone</span>
                    <AtSymbolIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </TabList>

            <div v-if="canEdit">
              <div class="flex justify-end">
                <button
                  type="submit"
                  class="inline-flex items-center gap-x-1.5 rounded-md bg-slate-700 py-1.5 px-2.5 text-sm font-semibold text-slate-400 shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 border border-slate-900"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </TabGroup>
      </div>
    </div>
  </form>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import {
  AtSymbolIcon,
  CodeBracketIcon,
  LinkIcon,
} from "@heroicons/vue/20/solid";
import Markdown from "vue3-markdown-it";
import "highlight.js/styles/stackoverflow-dark.css";

export default {
  components: {
    Markdown,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    AtSymbolIcon,
    CodeBracketIcon,
    LinkIcon,
  },
  props: {
    text: String,
    editing: Boolean,
    canEdit: Boolean,
  },
  emits: ["save"],
  data() {
    return {
      isEditing: false,
      tmpText: "",
    };
  },
  computed: {
    selectedTab() {
      if (this.isEditing) {
        return 1;
      }
      return 0;
    },
  },
  mounted() {
    this.isEditing = this.editing || !this.text;
    this.tmpText = this.text;
  },
  methods: {
    setText(event) {
      this.tmpText = event.target.value;
    },
    emitSubmit() {
      this.isEditing = 0;
      this.$emit("save", this.tmpText);
    },
    changeTab(index) {
      this.isEditing = index;
    },
    showEditor() {
      this.isEditing = 1;
    },
    // Replace whitespace characters at the beginning of each line with non-breaking spaces
    preserveWhitespace(str) {
      return str.replace(/^\s+/gm, (match) => "&nbsp;".repeat(match.length));
    },
  },
};
</script>

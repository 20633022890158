<template>
  <TransitionRoot as="template" :show="show">
    <DialogVue as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="close"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900 py-2"
                  >
                    Move Clip
                  </DialogTitle>
                  <hr />
                </div>
              </div>
              <div class="mt-2 sm:ml-4">
                <p class="text-sm text-slate-600 py-2">Currently in:</p>
                <p
                  class="text-sm text-gray-400 ml-5 py-2 sm:flex sm:items-center"
                >
                  <FolderIcon
                    class="h-6 w-6 mx-2 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ currentProject?.name }}
                </p>
              </div>
              <div class="mt-2 sm:ml-4">
                <p class="text-sm text-slate-600 py-2">Move to:</p>
                <p
                  v-for="project in projectsExcludingCurrent"
                  :key="project.id"
                  :class="[
                    project.id === selectedProject?.id
                      ? 'bg-indigo-600 text-white'
                      : 'text-gray-500 hover:bg-gray-300',
                    'text-sm pl-5 py-2 sm:flex sm:items-center rounded-md hover:cursor-pointer',
                  ]"
                  @click="selectProject(project)"
                >
                  <FolderOpenIcon
                    :class="[
                      project.id === selectedProject?.id
                        ? 'text-white'
                        : 'text-gray-500',
                      'h-6 w-6 mx-2',
                    ]"
                    aria-hidden="true"
                  />
                  {{ project?.name }}
                </p>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  :disabled="!selectedProject"
                  @click="confirmMoveReplay"
                  :class="[
                    selectedProject
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2'
                      : 'text-gray-500 bg-gray-200',
                    'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm',
                  ]"
                >
                  Move
                </button>
                <button
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  @click="close"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </DialogVue>
  </TransitionRoot>
</template>

<script>
import {
  Dialog as DialogVue,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  FolderIcon,
  FolderOpenIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { mapState } from "vuex";

export default {
  components: {
    DialogVue,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    FolderIcon,
    FolderOpenIcon,
    XMarkIcon,
  },
  props: {
    show: Boolean,
    replay: Object,
  },
  emits: ["confirm-move-replay", "close-replay-move-modal"],
  data() {
    return {
      selectedProject: null,
    };
  },
  computed: {
    ...mapState(["projects"]),
    currentProject() {
      return this.replay?.project;
    },
    projectsExcludingCurrent() {
      return this.projects.filter(
        (project) => project.id !== this.currentProject.id
      );
    },
  },
  methods: {
    close() {
      this.$emit("close-replay-move-modal");
      // Reset the selected project on modal close
      this.selectedProject = null;
    },
    selectProject(project) {
      this.selectedProject = project;
    },
    confirmMoveReplay() {
      this.$emit("confirm-move-replay", this.selectedProject);
    },
  },
};
</script>

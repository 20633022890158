<template>
  <div class="p-2 border-b border-slate-800 shadow">
    <div class="relative mt-2">
      <label for="name" class="absolute -top-2 left-2 inline-block text-white"
        >Filter</label
      >
      <input
        type="text"
        name="name"
        v-model="filterText"
        id="name"
        class="block w-full cursor-pointer bg-slate-800 border-slate-900 text-slate-400 hover:bg-slate-700 items-center rounded-r-sm px-4 py-2 text-sm font-medium"
        placeholder=""
      />
    </div>
  </div>
  <div
    ref="scrollParent"
    class="flex flex-1 min-h-0 flex-col font-mono select-text overflow-y-auto"
    style="background-color: #010917"
  >
    <div
      v-for="(event, index) in filteredEvents"
      :key="event.time + '_' + index"
      class="flex items-start border-b border-slate-800 px-2 py-1"
      :class="lineClasses(event)"
    >
      <span
        class="select-none text-sm font-medium text-blue-500 whitespace-nowrap rounded-md cursor-pointer font-mono"
        :class="[event.time <= videoTime ? 'opacity-100' : 'opacity-60']"
        @click="setVideoTime(event.time)"
      >
        {{ timeDisplay(event.time) }}
      </span>
      <a
        :class="[
          ,
          lineIcon(event) === '' ? 'pl-2' : 'py-1',
          event.time <= videoTime ? 'opacity-100' : 'opacity-40',
          'flex flex-1 cursor-pointer items-start break-normal border-slate-300 px-2',
        ]"
        :href="
          event.type === 'NAVIGATION_STARTED' || event.type === 'SPA_NAVIGATION'
            ? event.payload.url
            : '#'
        "
        :target="
          event.type === 'NAVIGATION_STARTED' || event.type === 'SPA_NAVIGATION'
            ? '_blank'
            : ''
        "
        :title="titleText(event)"
      >
        <!-- @click.prevent="setVideoTime(event.time)" -->
        <XCircleIcon
          class="text-red-500 w-4 shrink-0 mr-1 mt-0.5"
          v-if="lineIcon(event) === 'error'"
        />
        <ExclamationTriangleIcon
          class="text-yellow-500 w-4 shrink-0 mr-1 mt-0.5"
          v-if="lineIcon(event) === 'warn'"
        />
        <div class="flex-1">
          <div v-for="item in lineText(event)" :key="item">
            <details
              class="inline-block"
              :title="JSON.stringify(item, null, 2)"
              v-if="typeof item !== 'string'"
            >
              <summary class="cursor-pointer">
                {{ Array.isArray(item) ? "Array" : "Object" }}
              </summary>

              <pre
                v-highlightjs
              ><code class="json rounded cursor-default">{{ JSON.stringify(item, null, 2) }}</code></pre>
            </details>
            {{ typeof item === "string" ? item : "" }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { XCircleIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/solid";

export default {
  components: { XCircleIcon, ExclamationTriangleIcon },
  props: ["consoleEvents", "videoTime", "isPlaying"],
  emits: ["setVideoTime"],
  data() {
    return {
      filterText: "",
    };
  },
  computed: {
    searchTerms() {
      return this.filterText
        .split(" ")
        .map((s) => s.trim().toLowerCase())
        .filter((s) => s.length);
    },
    filteredEvents() {
      return this.consoleEvents.filter((event) => {
        const text = this.titleText(event).toLowerCase();
        return (
          this.searchTerms.every((term) => text.includes(term)) &&
          (!this.isPlaying || event.time <= this.videoTime)
        );
      });
    },
  },
  watch: {
    filteredEvents() {
      if (!this.isPlaying) return; // We don't want to scroll if paused
      this.$nextTick(() => {
        this.$refs.scrollParent.scrollTop =
          this.$refs.scrollParent.scrollHeight;
      });
    },
  },
  methods: {
    manualScroll() {
      this.$emit("manualScroll");
    },
    lineClasses(event) {
      const typeStyles = {
        LOG_ERROR: "bg-red-100 text-red-600",
        NAVIGATION_STARTED: "text-blue-500",
        SPA_NAVIGATION: "text-blue-500",
      };
      const methodStyles = {
        warn: "bg-yellow-100 text-yellow-600",
        error: typeStyles.LOG_ERROR,
      };
      switch (event.type) {
        case "LOG_EVENT":
          return methodStyles[event.payload.method] ?? "text-slate-400";
        case "LOG_ERROR":
        case "SPA_NAVIGATION":
        case "NAVIGATION_STARTED":
          return typeStyles[event.type];
        default:
          console.error(
            `event of type ${event.type} is not handled by the ConsoleTab.vue component`
          );
          return "";
      }
    },
    lineIcon(event) {
      if (event.type === "LOG_ERROR") return "error";
      if (event.type !== "LOG_EVENT") return "";
      if (["warn", "error"].includes(event.payload.method))
        return event.payload.method;
      return "";
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    lineText(event) {
      if (event.type === "LOG_ERROR") {
        if (event.payload.type === "error" || event.payload.error)
          return [
            `${
              event.payload.error.message.startsWith("Uncaught Error:")
                ? ""
                : "Uncaught Error: "
            }${event.payload.error.message || ""}`,
          ];

        if (event.payload.type === "unhandledrejection") {
          switch (event.payload.reasonType) {
            case "error":
              return [
                `Uncaught (in promise) Error: ${event.payload.reason.message}`,
              ];
            case "string":
            case "number":
            case "boolean":
            case "undefined":
            case "null":
              return [`Uncaught (in promise) ${event.payload.reason}`];
            case "object":
              return ["Uncaught (in promise) ", event.payload.reason];
            default:
              console.error(
                "Unrecognized reason type",
                event.payload.reasonType,
                reason
              );
              return [""];
          }
        }
        console.error("Unrecognized error type", event.payload.error.type);
        return [""];
      }

      if (event.type === "SPA_NAVIGATION")
        return [`SPA pushed url: ${event.payload.url}`];
      if (event.type === "NAVIGATION_STARTED")
        return [`Navigated to url: ${event.payload.url}`];
      if (event.type === "LOG_EVENT") {
        return event.payload.arguments
          .map((arg) =>
            // eslint-disable-next-line no-nested-ternary
            ["string", "number", "boolean", "undefined"].includes(typeof arg)
              ? `${arg}`
              : arg === null
              ? "null"
              : arg
          )
          .reduce((parts, arg) => {
            if (typeof arg === "string") {
              const last = parts.pop() || "";
              if (typeof last === "string") parts.push(`${last} ${arg}`);
              else parts.push(last, arg);
            } else parts.push(arg);
            return parts;
          }, []);
      }
      return [""];
    },
    titleText(event) {
      return this.lineText(event)
        .map((arg) =>
          typeof arg === "string" ? arg : JSON.stringify(arg, null, 2)
        )
        .join("\n");
    },
    setVideoTime(time) {
      this.$emit("setVideoTime", time);
    },
    timeDisplay(duration) {
      let d = duration;
      if (d < 0) {
        d = 0;
      }
      const minutes = moment(d).minutes().toString().padStart(2, 0);
      const seconds = moment(d).seconds().toString().padStart(2, 0);
      return `${minutes}:${seconds}`;
    },
  },
};
</script>

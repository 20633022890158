<template>
  <MenuVue as="div" class="relative inline-block text-left z-10">
    <div>
      <MenuButton
        ref="menuButton"
        class="inline-flex justify-center w-full rounded-md border border-slate-900 shadow px-4 py-2 bg-slate-700 text-sm font-medium text-slate-400 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-blue-900"
      >
        <LinkIcon class="-ml-1 mr-3 h-5 w-5" /> Share
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        style="width: 500px"
        class="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <BarLoader v-if="isLoading" />
        <div
          class="p-4 rounded-md"
          :class="{ 'pointer-events-none': isLoading }"
        >
          <div class="mb-6">
            <label class="text-base font-semibold text-gray-900">
              Share this Dash
            </label>
          </div>
          <div v-if="canUpdateReplay" class="mb-6">
            <label
              id="headlessui-listbox-label-232"
              data-headlessui-state=""
              class="block text-sm font-medium text-gray-600 mb-2"
              >Add People</label
            >
            <div class="flex items-center justify-between">
              <input
                placeholder="Email Address"
                required
                v-model="inviteEmail"
                type="email"
                name="invite-email"
                id="invite-email"
                :class="{ 'border-red-500': inviteEmailError }"
                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500 mr-2"
              />
              <button
                @click="inviteUserForAccess(inviteEmail)"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Invite
              </button>
            </div>
            <p v-if="inviteEmailError" class="text-red-500 text-xs italic pt-2">
              {{ inviteEmailError }}
            </p>
          </div>

          <div v-if="accessInvites?.length" class="mb-4 text-sm text-gray-700">
            <label class="text-sm font-medium text-gray-600 block">
              People with access
            </label>
            <div class="divide-y divide-gray-200">
              <div
                v-for="invite in accessInvites"
                :key="invite"
                class="flex items-center justify-between w-full py-2"
              >
                <div class="flex items-center">
                  <UserCircleIcon
                    class="h-10 w-10 rounded-full mr-1"
                    aria-hidden="true"
                  />
                  {{ invite }}
                </div>
                <button
                  @click.prevent="deleteAccessInvite(invite)"
                  type="button"
                  class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <TrashIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <Selector
            :disabled="!canUpdateReplay"
            :options="accessOptions"
            :selected-id="activeAccessOption"
            @option-selected="accessOptionSelected"
            label="General Access"
            :class="{
              'opacity-50': !canUpdateReplay,
            }"
            selector-class="relative w-full mt-2"
          />
          <div class="flex items-center justify-between mt-6">
            <div class="flex items-center">
              <ReplayCopyDropdown @copy-link="copyLink" />
            </div>
          </div>
        </div>
      </MenuItems>
    </transition>
  </MenuVue>
</template>

<script>
import { datadogLogs as log } from "@datadog/browser-logs";

import {
  ChevronDownIcon,
  LinkIcon,
  BookOpenIcon,
  CodeBracketIcon,
  UserCircleIcon,
  TrashIcon,
} from "@heroicons/vue/20/solid";

import {
  Menu as MenuVue,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import Selector from "./Selector.vue";
import ReplayCopyDropdown from "./ReplayCopyDropdown.vue";

import BarLoader from "./BarLoader.vue";

export default {
  components: {
    MenuVue,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    LinkIcon,
    BookOpenIcon,
    CodeBracketIcon,
    UserCircleIcon,
    TrashIcon,
    Selector,
    ReplayCopyDropdown,
    BarLoader,
  },
  props: {
    canUpdateReplay: {
      type: Boolean,
      required: true,
    },
    activeAccessOption: {
      type: String,
      required: true,
    },
    replayId: {
      type: String,
      default: null,
    },
    accessInvites: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "copy-link",
    "access-option-selected",
    "invite-member",
    "replay-updated",
  ],
  data() {
    return {
      accessOptions: [
        {
          id: "team",
          name: "My Team",
        },
        {
          id: "unlisted",
          name: "Anyone with the link",
        },
      ],
      inviteEmail: "",
      inviteEmailError: false,

      copyMode: {
        parentId: "link",
        name: "Link",
      },

      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    if (this.$route.query.invitee) {
      this.$refs.menuButton.$el.click();
      this.inviteUserForAccess(this.$route.query.invitee);
    }
  },
  methods: {
    accessOptionSelected(optionId) {
      this.$emit("access-option-selected", optionId);
    },
    copyLink(copyMode) {
      this.$emit("copy-link", copyMode);
    },
    async inviteUserForAccess(email) {
      if (!email) {
        this.inviteEmailError = "Email is required";
        return;
      }

      if (
        // eslint-disable-next-line no-useless-escape
        !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
          email
        )
      ) {
        this.inviteEmailError = "Email is invalid";
        return;
      }

      this.isLoading = true;

      this.inviteEmailError = "";
      const updatedReplay = await Cloud.replayAccessInvite({
        replayId: this.replayId,
        email,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedReplay) {
        this.$toast.success(`${email} was invited to access this replay`);

        this.$emit("replay-updated", {
          replay: updatedReplay,
          fields: ["accessInvites"],
        });
        this.inviteEmail = "";
      }

      this.isLoading = false;
    },
    async deleteAccessInvite(email) {
      if (!email) {
        this.inviteEmailError = "Email is required";
        return;
      }

      if (
        // eslint-disable-next-line no-useless-escape
        !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
          email
        )
      ) {
        this.inviteEmailError = "Email is invalid";
        return;
      }

      this.isLoading = true;

      this.inviteEmailError = "";
      const updatedReplay = await Cloud.replayAccessDelete({
        replayId: this.replayId,
        email,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      this.$toast.success(`${email} was deleted from replay access`);

      if (updatedReplay) {
        this.$emit("replay-updated", {
          replay: updatedReplay,
          fields: ["accessInvites"],
        });
        this.inviteEmail = "";
      }

      this.isLoading = false;
    },
  },
};
</script>

/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
// eslint-disable-next-line import/prefer-default-export
export const process = (events) => {
  const result = { tabs: {}, activeTabs: [] };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  events.forEach((event, i) => {
    try {
      const { type, time, payload } = event || {};
      switch (type) {
        case "INITIAL_TABS":
          payload.forEach(({ tabId, title, url, favIconUrl }) => {
            tabId = tabId === null ? "0" : parseInt(tabId, 10);
            if (!result.tabs[tabId])
              result.tabs[tabId] = [
                {
                  // This is a hack currently as there is a delay for before the desktop
                  // starts recording and before it starts receiving messages from the
                  // extension
                  time: 0,
                  url,
                  title,
                  favIconUrl,
                  errors: [],
                  logs: [],
                  network: [],
                  spaNavigation: [],
                },
              ];
          });
          break;
        case "LOG_ERROR":
          if (payload.type === "error" || payload.error)
            _.last(result.tabs[payload.tabId]).errors.push({
              time,
              error: payload.error,
              type: payload.type || "error",
            });
          else if (payload.type === "unhandledrejection")
            _.last(result.tabs[payload.tabId]).errors.push({
              time,
              type: payload.type,
              reasonType: payload.reasonType,
              reason: payload.reason,
            });
          break;
        case "LOG_EVENT":
          _.last(result.tabs[payload.tabId]).logs.push({
            time,
            method: payload.method,
            arguments: payload.arguments,
          });
          break;
        case "NETWORK_BEFORE_REQUEST":
          // There is some edge case where this is sent before the navigation
          // So we make it optional here
          _.last(result.tabs[payload.tabId])?.network?.push({
            time,
            status: "start",
            details: payload,
          });
          break;
        case "NETWORK_COMPLETED_REQUEST":
          _.last(result.tabs[payload.tabId])?.network?.push({
            time,
            status: "completed",
            details: payload,
          });
          break;
        case "NETWORK_RESPONSE_BODY":
          const completedEvent = events
            .slice(0, i)
            .find(
              (event) =>
                event.payload.requestId === payload.requestId &&
                event.type === "NETWORK_COMPLETED_REQUEST"
            );
          if (completedEvent) {
            completedEvent.responseBody = payload.responseBody;
            const session = result.tabs[payload.tabId].find((session) =>
              session.network.some(
                (event) =>
                  event.status === "completed" &&
                  event.details.requestId === payload.requestId
              )
            );
            if (session)
              session.network.find(
                (event) =>
                  event.status === "completed" &&
                  event.details.requestId === payload.requestId
              ).details.responseBody = payload.responseBody;
          }
          break;
        case "NETWORK_ERROR_REQUEST":
          _.last(result.tabs[payload.tabId]).network.push({
            time,
            status: "error",
            details: payload,
          });
          break;
        case "TAB_ACTIVATED":
          if (result.activeTabs.length) _.last(result.activeTabs).to = time;
          if (Object.keys(payload).length)
            result.activeTabs.push({
              tabId: payload.tabId,
              from: time,
            });
          if (!result.tabs[payload.tabId])
            result.tabs[payload.tabId] = [
              {
                time,
                url: payload.url,
                title: payload.title,
                favIconUrl: payload.favIconUrl,
                errors: [],
                logs: [],
                network: [],
                spaNavigation: [],
              },
            ];
          break;
        case "SPA_NAVIGATION":
          _.last(result.tabs[payload.tabId])?.spaNavigation?.push({
            time,
            url: payload.url,
            title: payload.title,
          });
          break;
        case "NAVIGATION_STARTED":
          if (!result.tabs[payload.tabId]) result.tabs[payload.tabId] = [];
          result.tabs[payload.tabId].push({
            time,
            url: payload.url,
            title: payload.title,
            favIconUrl: payload.favIconUrl,
            errors: [],
            logs: [],
            network: [],
            spaNavigation: [],
          });
          break;
        case "NAVIGATION_COMPLETED":
          const last = _.last(result.tabs[payload.tabId]);
          if (!last || last.url !== payload.url) break;
          last.title = payload.title;
          last.favIconUrl = payload.favIconUrl;
          break;
        default:
          console.error("Unrecognized weblogs event", event);
      }
      if (
        type &&
        type !== "TAB_ACTIVATED" &&
        type !== "INITIAL_TABS" &&
        type !== "NETWORK_RESPONSE_BODY" &&
        _.last(result.tabs[payload.tabId])
      )
        _.last(result.tabs[payload.tabId]).lastActivityTimestamp = time;
    } catch (err) {
      console.log("Failed processing weblogs event", event.type, {
        err,
        event,
        result,
      });
    }
  });
  Object.keys(result.tabs).forEach((tabId) => {
    result.tabs[tabId] = result.tabs[tabId].filter((session) => !!session.url);
    if (result.tabs[tabId].length === 0) delete result.tabs[tabId];
    // TODO: Fix trimming in desktop to include the last TAB_ACTIVATED before the
    // timestamp

    if (result.tabs[tabId]) {
      const hasActivity =
        result.tabs[tabId]
          .map(
            ({ errors, logs, network, spaNavigation }) =>
              errors.length +
              logs.length +
              network.length +
              spaNavigation.length
          )
          .reduce((a, b) => a + b) !== 0;

      // We only filter by no activity if we don't have an activation event for the tab
      // This keeps everything working as it was while reducing the noise on falty trimming
      // Due to missing activation events.
      if (
        !result.activeTabs.find((activeTab) => activeTab.tabId === tabId) &&
        !hasActivity
      )
        delete result.tabs[tabId];
    }
  });
  return result;
};
